<template>
  <div class="schedul-detail">
    <div class="btn-container">
      <!--el-button
        v-if="jobData.id && schedulFormData.mitigatingCompanyOrgId === currentCompany.id"
        round
        @click="() => (showEmailRecipientDialog = true)"
        >Recipients</el-button
      -->
      <!--取消订单-->
      <el-button
        v-if="
          ![4, 5].includes(jobData.status) &&
          jobData.status &&
          !isCanceled &&
          hasPermission($permissionFunctionCode.EDIT) &&
          isJobCreatorOrBuyerEditView
        "
        style="min-width: 100px"
        type="danger"
        size="small"
        round
        :loading="cancelLoading"
        @click="handleCancelJob"
      >
        Cancel Job
      </el-button>
      <!--提交-->
      <el-button
        v-if="([1, 2].includes(jobData.status) || !isEdit) && isJobCreatorOrBuyerEditView"
        style="min-width: 100px"
        type="primary"
        size="small"
        round
        :loading="submitLoading"
        @click="submitForm"
      >
        Submit
      </el-button>
      <el-button
        v-if="jobData.status == 4 && isJobCreatorOrBuyerEditView"
        style="min-width: 100px"
        type="primary"
        size="small"
        round
        :loading="submitLoading"
        @click="submitReferenceNo"
      >
        Submit Reference No
      </el-button>
      <!--关闭当前页-->
      <el-button
        style="min-width: 100px"
        outline
        size="small"
        round
        @click="$emit('close', true)"
      >
        Close
      </el-button>
    </div>
    <el-form
      class="main-scroll-form"
      ref="schedulForm"
      :model="schedulFormData"
      :disabled="
        isCanceled || [3, 4, 5].includes(jobData.status) || !isJobCreatorOrBuyerEditView
      "
      label-position="top"
      :validate-on-rule-change="false"
    >
      <base-detail-card
        title="Files"
        v-if="jobData.status && [3, 4].includes(jobData.status)"
      >
        <job-files :jobFiles.sync="jobFiles" />
      </base-detail-card>
      <!--  Delivered Information-->
      <base-detail-card title="Delivered Information" v-if="isEdit" v-loading="!jobDataInner">
        <el-form
          ref="deliveredScheduleForm"
          :model="jobDataInner"
          label-position="top"
          :validate-on-rule-change="false"
        >
          <el-row :gutter="10">
            <el-col :sm="12" :md="8" :lg="4">
              <el-form-item label="Product Name">
                <el-input
                  class="single-border"
                  readonly
                  :value="(jobDataInner || {}).productName"
                >
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :sm="12" :md="8" :lg="4">
              <el-form-item label="Barge Name">
                <el-input
                  class="single-border"
                  readonly
                  :value="(jobDataInner || {}).bargeName"
                >
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :sm="12" :md="8" :lg="4">
              <el-form-item label="eBDN Date" style="width: 100%">
                <el-date-picker
                  class="single-border"
                  v-model="(jobDataInner || {}).docDate"
                  type="date"
                  readonly
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :sm="12" :md="8" :lg="4">
              <el-form-item label="Delivered Quantity">
                <el-input
                  class="single-border"
                  readonly
                  :value="numericByCompanySetting((jobDataInner || {}).docQty)"
                >
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :sm="12" :md="8" :lg="4">
              <el-form-item label="ETC">
                <el-date-picker
                  class="single-border"
                  v-model="(jobDataInner || {}).etc"
                  type="date"
                  readonly
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :sm="12" :md="8" :lg="4">
              <el-form-item v-if="(jobDataInner || {}).bdnPdfId" label="BDN Document">
                <el-button
                  type="primary"
                  plain
                  @click="previewFileMixin((jobDataInner || {}).bdnPdfId)"
                  style="width: 100%"
                >
                  {{ (jobDataInner || {}).docNumber }}
                </el-button>
              </el-form-item>
            </el-col>
          </el-row>
          <time-sheet-information v-if="jobDataInner?.timesheet" :jobDataInner ="jobDataInner" :jobData ="jobData" />
        </el-form>
      </base-detail-card>
      <!-- Product Information -->
      <base-detail-card title="Product Information">
        <div>
          <el-row :gutter="10" v-if="!isEdit">
            <el-col :span="8">
              <el-button
                type="outlined"
                size="mini"
                round
                plain
                @click="dialogTableVisible = true"
                >Select Order</el-button
              >
            </el-col>
          </el-row>
          <el-row :gutter="10">
            <el-col :sm="24" :md="12" :lg="8">
              <el-form-item v-if="!isEdit" prop="orderId" label="Linked Order">
                <el-input
                  disabled
                  v-model="schedulFormData.salesOrderNumber"
                  style="width: 100%"
                ></el-input>
                <el-dialog title="Link Order" :visible.sync="dialogTableVisible">
                  <div>
                    <el-input
                      class="round-input"
                      placeholder="Search Vessel Name"
                      v-model="orderSearchKeyword"
                      @keypress.enter.native="getOrders(orderPagination.pageSize, 0)"
                    >
                      <el-button
                        type="primary"
                        slot="append"
                        icon="el-icon-search"
                        @click="getOrders(orderPagination.pageSize, 0)"
                      ></el-button>
                    </el-input>
                  </div>
                  <el-table
                    :data="orderTableData"
                    ref="multipleTable"
                    height="550px"
                    v-loading="orderLoading"
                  >
                    <el-table-column width="55">
                      <template v-slot="scope">
                        <el-radio v-model="selectedOrder" :label="scope.row">
                          <i></i>
                        </el-radio>
                      </template>
                    </el-table-column>
                    <el-table-column prop="vesselName" label="Vessel Name" width="150" />
                    <el-table-column
                      prop="orderType"
                      label="Order Type"
                      width="150"
                    ></el-table-column>
                    <el-table-column
                      prop="salesOrderNo"
                      label="Order Number"
                      width="170"
                    ></el-table-column>
                    <el-table-column
                      prop="buyerCompanyName"
                      label="Buyer "
                      width="200"
                    ></el-table-column>
                    <el-table-column
                      prop="sellerCompanyName"
                      label="Seller"
                      width="200"
                    ></el-table-column>
                    <el-table-column prop="products" label="Product" width="220">
                      <template slot-scope="scope">
                        <div v-for="(item, index) in scope.row.products" :key="index">
                          {{ item.sysProductName }}
                        </div>
                      </template>
                    </el-table-column>
                    <el-table-column prop="products" label="Nomination Qty" width="200">
                      <template slot-scope="scope">
                        <div v-for="(item, index) in scope.row.products" :key="index">
                          {{ numberWithCommas(Number(item.finalQuantity).toFixed(3)) }}
                        </div>
                      </template>
                    </el-table-column>
                    <el-table-column
                      prop="products"
                      label="Verified Delivered Qty"
                      width="200"
                    >
                      <template slot-scope="scope">
                        <div v-for="(item, index) in scope.row.products" :key="index">
                          {{
                            item.verifiedQuantity
                              ? numberWithCommas(Number(item.verifiedQuantity).toFixed(3))
                              : "-"
                          }}
                        </div>
                      </template>
                    </el-table-column>
                    <el-table-column
                      prop="products"
                      label="Physical Supplier"
                      width="150"
                    >
                      <template slot-scope="scope">
                        <div v-for="(item, index) in scope.row.products" :key="index">
                          {{ item.physicalSupplierName }}
                        </div>
                      </template>
                    </el-table-column>
                    <el-table-column
                      prop="remarks"
                      label="Remarks "
                      width="260"
                      show-overflow-tooltip=""
                    >
                      <template slot-scope="scope">
                        <div>{{ scope.row.remarks || "-" }}</div>
                      </template>
                    </el-table-column>
                  </el-table>
                  <div class="pagination-container">
                    <el-pagination
                      background
                      @size-change="handleOrderPageSizeChange"
                      @current-change="handleOrderPageChange"
                      :page-size.sync="orderPagination.pageSize"
                      :page-sizes="[5, 10, 20, 50, 100]"
                      :current-page="orderPagination.pageNumber"
                      layout="total, sizes, prev, pager, next"
                      :total="orderPagination.total"
                    >
                    </el-pagination>
                  </div>
                  <div slot="footer" class="dialog-footer">
                    <el-button
                      round
                      plain
                      size="small"
                      style="min-width: 100px"
                      @click="dialogTableVisible = false"
                      >Close</el-button
                    >
                    <el-button
                      round
                      size="small"
                      type="primary"
                      style="min-width: 100px"
                      @click="ConfirmDialogTableVisible"
                      >Confirm</el-button
                    >
                  </div>
                </el-dialog>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-button
                v-if="!isEdit && withOrder"
                type="danger"
                icon="el-icon-delete"
                circle
                size="mini"
                @click="clearOrder(true)"
                style="position: absolute; bottom: 15px"
              ></el-button>
            </el-col>
          </el-row>
          <el-row
            :gutter="10"
            v-if="
              schedulFormData.mitigatingCompanyOrgId === currentCompany.id &&
              currentCompanySettings.pricingTierCheck
            "
          >
            <el-col>
              <div style="padding-bottom: 5px">
                <span style="padding-right: 10px">
                  <input
                    type="radio"
                    value="non-cash"
                    v-model="cashType"
                    :disabled="jobData.status >= 3"
                  />
                  <label for="non-cash">Non-Cash Order</label>
                </span>
                <span style="padding-right: 10px">
                  <input
                    type="radio"
                    value="cash"
                    v-model="cashType"
                    :disabled="jobData.status >= 3"
                  />
                  <label for="cash">Cash Order</label>
                </span>
              </div>
            </el-col>
          </el-row>
          <el-row :gutter="10">
            <el-col v-if="cashType == 'cash' && currentCompanySettings.pricingTierCheck" :sm="12" :md="8">
              <el-form-item label="Pricing">
                <el-input :disabled="true" v-model="tier4Price"></el-input>
              </el-form-item>
            </el-col>
            <el-col v-else :sm="24" :md="8" >
              <!--  :rules="!isEdit ? rules.reqRule : null" -->
              <el-form-item
                v-if="schedulFormData.mitigatingCompanyOrgId === currentCompany.id"
                prop="buyerSysOrganizationId"
                label="Company Name"
                :rules="currentCompanySettings.pricingTierCheck ? rules.reqRule : null"
              >
                <el-select
                  v-model="schedulFormData.buyerSysOrganizationId"
                  style="width: 100%"
                  placeholder="Select"
                  filterable
                  :clearable="true"
                  :disabled="jobData.status >= 3"
                  @visible-change="$store.dispatch('getCreditAssociationList')"
                >
                  <el-option
                    v-for="item in creditAssociationList"
                    :key="item.id"
                    :label="item.counterpartyCompanyName"
                    :value="item.counterpartySysOrganizationId"
                    :disabled="item.status !== 'ACTIVE'"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item v-else label="Company Name">
                <el-input v-model="schedulFormData.buyer" disabled/>
              </el-form-item>
            </el-col>
          <!-- </el-row>
          <el-row :gutter="10"> -->
            <el-col :sm="24" :md="8">
              <el-form-item
                prop="stemStartDate"
                label="Stem Start Date"
                :rules="rules.reqRule"
              >
                <el-date-picker
                  :disabled="withOrder || jobData.status === 3"
                  v-model="schedulFormData.stemStartDate"
                  type="date"
                  style="width: 100%"
                  placeholder="Select"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :sm="24" :md="8">
              <el-form-item
                prop="stemEndDate"
                label="Stem End Date"
                :rules="rules.reqRule"
              >
                <el-date-picker
                  :disabled="withOrder || jobData.status === 3"
                  v-model="schedulFormData.stemEndDate"
                  type="date"
                  style="width: 100%"
                  placeholder="Select"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row v-if="withOrder" :gutter="10" class="product-items">
            <el-col :span="24">
              <p>Order Products</p>
              <el-table
                style="background-color: #f6f7fa"
                :data="selectedOrder.products"
                border
                width="100%"
                :header-cell-style="{
                  fontSize: '16px',
                  color: '#C0C4CC',
                  height: '40px',
                  backgroundColor: '#ebedf0',
                }"
                :cell-style="{ height: '40px', backgroundColor: '#F6F7FA' }"
              >
                <el-table-column prop="sysProductName" label="Product Name">
                  <template v-slot="scope">
                    <p style="margin: 0; color: #c0c4cc">
                      {{ scope.row.sysProductName }}
                    </p>
                  </template>
                </el-table-column>
                <el-table-column prop="sysProductSpecificationName" label="Specs">
                  <template v-slot="scope">
                    <p style="margin: 0; color: #c0c4cc">
                      {{ scope.row.sysProductSpecificationName }}
                    </p>
                  </template>
                </el-table-column>
                <el-table-column prop="minimumQuantity" label="Min Qty">
                  <template v-slot="scope">
                    <p style="margin: 0; color: #c0c4cc">
                      {{ scope.row.minimumQuantity }}
                    </p>
                  </template>
                </el-table-column>
                <el-table-column prop="maximumQuantity" label="Max Qty">
                  <template v-slot="scope">
                    <p style="margin: 0; color: #c0c4cc">
                      {{ scope.row.maximumQuantity ? scope.row.maximumQuantity : "-" }}
                    </p>
                  </template>
                </el-table-column>
                <el-table-column prop="finalQuantity" label="Final Qty">
                  <template v-slot="scope">
                    <p style="margin: 0; color: #c0c4cc">
                      {{ scope.row.finalQuantity ? scope.row.finalQuantity : "-" }}
                    </p>
                  </template>
                </el-table-column>
                <el-table-column prop="unitMetric" label="Unit Metric">
                  <template v-slot="scope">
                    <p style="margin: 0; color: #c0c4cc">{{ scope.row.unitMetric }}</p>
                  </template>
                </el-table-column>
                <el-table-column prop="physicalSupplierBdn" label="Physical Supplier BDN">
                  <template v-slot="scope">
                    <p style="margin: 0; color: #c0c4cc">
                      {{ scope.row.physicalSupplierName }}
                    </p>
                  </template>
                </el-table-column>
              </el-table>
              <p v-if="selectedOrder.sellerRemark">
                *Remarks: {{ selectedOrder.sellerRemark }}
              </p>
            </el-col>
          </el-row>
          <el-row :gutter="10">
            <el-col :sm="24" :md="8">
              <el-form-item prop="supplyType" label="Supply Type" :rules="rules.reqRule">
                <el-select
                  v-model="schedulFormData.supplyType"
                  style="width: 100%"
                  placeholder="Select"
                >
                  <el-option
                    v-for="item in SUPPLY_TYPE_OPTIONS"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <!-- <el-col :sm="24" :md="8">
              <el-form-item prop="bargeOrgId" label="BDN" :rules="rules.reqRule">
                <el-select
                  filterable
                  clearable
                  style="width: 100%"
                  v-model="schedulFormData.bargeOrgId"
                  :disabled="
                    withOrder ||
                    (isEdit &&
                      schedulFormData.mitigatingCompanyOrgId !== currentCompany.id)
                  "
                >
                  <el-option
                    v-for="item in physicalSupplierOptions"
                    :key="item.id"
                    :label="item.companyName"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col> -->
            <el-col :sm="24" :md="8">
              <el-form-item prop="nominationReference" :label="nominationReferenceLabel">
                <el-input v-model="schedulFormData.nominationReference" />
              </el-form-item>
            </el-col>
            <el-col :sm="24" :md="8">
              <el-form v-if="jobData.status==4 && isJobCreatorOrBuyerEditView">
                <el-form-item prop="referenceNo" label="Reference No." >
                  <el-input v-model="schedulFormData.referenceNo" />
                </el-form-item>
              </el-form>
              <el-form-item v-else prop="referenceNo" label="Reference No." >
                <el-input v-model="schedulFormData.referenceNo" />
              </el-form-item>
            </el-col>
          <!-- </el-row>
          <el-row :gutter="10"> -->
            <el-col :sm="24" :md="8">
              <el-form-item prop="minQty" label="Min Quantity" :rules="rules.minQty">
                <el-input
                  v-model="schedulFormData.minQty"
                  type="number"
                  class="input-with-select"
                >
                  <template slot="append">{{ schedulFormData.qtyUnitMetric }}</template>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :sm="24" :md="8">
              <el-form-item prop="maxQty" label="Max Quantity" :rules="rules.maxQty">
                <el-input
                  v-model="schedulFormData.maxQty"
                  type="number"
                  class="input-with-select"
                >
                  <template slot="append">{{ schedulFormData.qtyUnitMetric }}</template>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :sm="24" :md="8">
              <el-form-item
                prop="finalQty"
                label="Final Quantity"
                :rules="rules.finalQty"
              >
                <el-input
                  v-model="schedulFormData.finalQty"
                  type="number"
                  class="input-with-select"
                >
                  <el-select
                    v-model="schedulFormData.qtyUnitMetric"
                    slot="append"
                    disabled
                  >
                    <el-option value="MT"></el-option>
                    <el-option value="CBM"></el-option>
                    <el-option value="BARRELS"></el-option>
                    <el-option value="%"></el-option>
                  </el-select>
                </el-input>
              </el-form-item>
              <div v-if="withOrder" class="BalanceDiv">
                <div>
                  <div>Quantity Balance:</div>
                  <div>{{ qtyBalance }}MT</div>
                </div>
                <div>
                  <div>Quantity Assigned:</div>
                  <div>{{ qtyAssigned }}MT</div>
                </div>
                <div>
                  <div>Delivered:</div>
                  <div>{{ balanceData.qtyDelivered }}MT</div>
                </div>
              </div>
            </el-col>
          </el-row>
          <el-row :gutter="10">
            <el-col :sm="12" :md="8">
              <el-form-item
                prop="sysProductId"
                label="Product Name"
                :rules="rules.reqRule"
              >
                <el-select
                  v-if="withOrder"
                  v-model="selectedOrderProductSpec"
                  style="width: 100%"
                  placeholder="Select"
                  @change="changeProduct"
                >
                  <el-option
                    v-for="item in selectedOrderProductSpecOptions"
                    :key="item.key"
                    :label="item.sysProductName"
                    :value="item.key"
                  >
                  </el-option>
                </el-select>
                <el-select
                  v-else
                  v-model="schedulFormData.sysProductId"
                  style="width: 100%"
                  placeholder="Select"
                  @change="changeProduct"
                  @visible-change="getProductListSortByRecent"
                  :loading="!sysProductOptions.length>0"
                >
                  <el-option
                    v-for="item in sysProductOptions"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :sm="12" :md="8">
              <el-form-item
                prop="sysProductSpecificationId"
                label="Spec"
                :rules="rules.reqRule"
              >
                <el-select
                  v-model="schedulFormData.sysProductSpecificationId"
                  style="width: 100%"
                  placeholder="Select"
                  :disabled="withOrder"
                  @change="changeProductSpecification"
                >
                  <el-option
                    v-for="item in sysProductSpecificationOptions"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :sm="24" :md="8">
              <el-form-item
                prop="fuelTypeCode"
                label="Fuel Type (For MPA Reporting)"
                :rules="getFuelCodeRules"
              >
                <el-select
                  v-model="schedulFormData.fuelTypeCode"
                  style="width: 100%"
                  placeholder="Select"
                  @visible-change="getMpaFuelTypeListSortByRecent"
                  :loading="!mpaFuelTypeOptions.length>0"
                >
                  <el-option
                    v-for="item in mpaFuelTypeOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>

          </el-row>
          <fuel-type-related-information
            :schedulFormData.sync="schedulFormData"
          />

        </div>
      </base-detail-card>
      <!-- BDN info -->
      <base-detail-card title="BDN Information">
        <el-row :gutter="10">
          <el-col :sm="24" :md="8">
            <el-form-item prop="bargeOrgId" label="BDN" :rules="rules.reqRule">
              <el-select
                filterable
                clearable
                style="width: 100%"
                v-model="schedulFormData.bargeOrgId"
                :disabled="
                  withOrder ||
                  (isEdit && schedulFormData.mitigatingCompanyOrgId !== currentCompany.id)
                "
                @change="handleChangeBDN"
              >
                <el-option
                  v-for="item in physicalSupplierOptions"
                  :key="item.id"
                  :label="item.companyName"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :sm="24" :md="8">
              <el-form-item
                prop="numberOfBdn"
                label="No. Of BDN"
                :rules="rules.reqRule"
              >
                <el-select v-model="schedulFormData.numberOfBdn" style="width:100%" @change="handleChangeNumberOfBdn">
                  <el-option value="1">1</el-option>
                  <el-option value="2">2</el-option>
                  <el-option value="3">3</el-option>
                </el-select>
              </el-form-item>
          </el-col>
          <el-col :sm="12" :md="8" v-if="isExxonMobil">
            <el-form-item prop="customerName" label="Customer Name" :rules="rules.reqRule">
              <el-input v-model="schedulFormData.customerName"/>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="10" v-if="schedulFormData.bdnQuantities?.length > 1">
          <el-col :sm="24" :md="8" v-for="(item, index) in schedulFormData.bdnQuantities">
            <el-form-item :prop="`bdnQuantities.${index}`" :label="`Bdn Quantity ${index + 1}`" :rules="rules.bdnQuantity">
              <el-input
                v-model="schedulFormData.bdnQuantities[index]"
                :type="'number'"
                :min="0"
              >
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </base-detail-card>
      <!-- barge info -->
      <base-detail-card title="Barge Information">
        <el-row :gutter="10">
          <el-col :sm="12" :md="8">
            <el-form-item
              v-if="
                schedulFormData.mitigatingCompanyOrgId === currentCompany.id || !isEdit
              "
              prop="bargeName"
              label="Barge Name"
            >
            <barge-or-vessel-selector
              :disabled="[2, 3, 4, 5].includes(jobData.status) || !isJobCreatorOrBuyerEditView"
              :schedulFormData="schedulFormData"
              :availableBargesLoading="availableBargesLoading"
              :availableBargesOptions="availableBargesOptions"
              @handleChangeBarge="handleChangeBarge"
              @visibleChange="visibleChange"
            />

            <span v-if="supplyingBargeMpaStatus" style="font-size: 10px"
              >({{ supplyingBargeMpaStatus }})</span
            >
            </el-form-item>
            <el-form-item v-else label="Barge Name">
              <el-input v-model="schedulFormData.bargeName" disabled />
              <span v-if="supplyingBargeMpaStatus" style="font-size: 10px"
              >({{ supplyingBargeMpaStatus }})</span
            >
            </el-form-item>
          </el-col>
          <el-col :sm="12" :md="8">
            <!-- imo -->
            <el-form-item label="IMO Number">
              <el-input v-model="schedulFormData.bargeImo" disabled> </el-input>
            </el-form-item>
          </el-col>
          <el-col :sm="12" :md="8">
            <!-- licenceNo -->
            <el-form-item label="SB No.">
              <el-input v-model="barge.licenceNo" disabled> </el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </base-detail-card>
      <!-- vessel info -->
      <base-detail-card title="Vessel Information">
        <el-row :gutter="10" class="search-vessel-suffix-hidden">

          <el-col :sm="24" :md="8">
            <el-form-item label="Search Vessel" >
              <search-vessel-selector
              :disabled="withOrder ||[3, 4, 5].includes(jobData.status) ||!isJobCreatorOrBuyerEditView"
              :searchedVesselList.sync="searchedVesselList"
              :cashType="cashType"
              :schedulFormData="schedulFormData"
              @handleSelectVessel="handleSelectVessel"
             />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="10">
          <el-col :sm="24" :md="8">
            <el-form-item prop="vesselName" label="Vessel Name" :rules="rules.reqRule">
              <!--disableVesselName -->
              <el-input
                :disabled="
                  withOrder ||
                  [3, 4, 5].includes(jobData.status) ||
                  !isJobCreatorOrBuyerEditView
                "
                v-model="schedulFormData.vesselName"
              >
              </el-input>
              <!--  class="mpa-status-lable"  -->
              <span
                v-if="
                  receivingVesselMpaStatus &&
                  schedulFormData.vesselName != null &&
                  schedulFormData.vesselName.length > 0
                "
                style="font-size: 10px"
                >({{ receivingVesselMpaStatus }})</span
              >
            </el-form-item>
          </el-col>
          <el-col :sm="12" :md="8">
            <el-form-item label="IMO Number" prop="vesselImo" :rules="rules.vesselImoNo">
              <el-input
                v-model="schedulFormData.vesselImo"
                :disabled="
                  withOrder ||
                  [3, 4, 5].includes(jobData.status) ||
                  !isJobCreatorOrBuyerEditView
                "
              >
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :sm="12" :md="8">
            <el-form-item
              prop="vesselLicenseNo"
              label="License Number"
              :rules="LICENSE_NUMBKER_RULE"
            >
              <el-input
                v-model="schedulFormData.vesselLicenseNo"
                :disabled="
                  withOrder ||
                  [3, 4, 5].includes(jobData.status) ||
                  !isJobCreatorOrBuyerEditView
                "
              >
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :sm="24" :md="8">
            <el-form-item prop="vesselEmail" label="Email" :rules="GENERAL_EMAIL_RULE">
              <el-input
                v-model="schedulFormData.vesselEmail"
                :disabled="
                  withOrder ||
                  [3, 4, 5].includes(jobData.status) ||
                  !isJobCreatorOrBuyerEditView
                "
              >
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </base-detail-card>
      <!-- Location info -->
      <base-detail-card title="Location Information">
        <el-row :gutter="10">
          <el-col :sm="12" :md="8">
            <el-form-item prop="port" label="Port" :rules="rules.reqRule">
              <el-input v-model="schedulFormData.port"> </el-input>
            </el-form-item>
          </el-col>
          <el-col :sm="12" :md="8">
            <el-form-item prop="vesselEta" label="Date & Time">
              <el-date-picker
                v-model="schedulFormData.vesselEta"
                type="datetime"
                format="yyyy-MM-dd HH:mm"
                style="width: 100%"
                placeholder="Select"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :sm="12" :md="8">
            <el-form-item prop="locationLevel" label="Anchorage/Berth">
              <el-cascader
                clearable
                popper-class="el-cascader-long"
                style="width: 100%"
                v-model="schedulFormData.locationLevel"
                :options="locationList"
                :props="locationOptionProps"
              >
              </el-cascader>
            </el-form-item>
          </el-col>
        </el-row>
      </base-detail-card>
      <!-- Third Party Info -->
      <base-detail-card title="Third Party Information">
        <el-row :gutter="10">
          <el-col :sm="12" :md="8">
            <el-form-item prop="agentCompany" label="Agent Company">
              <el-input v-model="schedulFormData.agentCompany" @input="change">
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :sm="12" :md="8">
            <el-form-item prop="agentContact" label="Agent Contact">
              <el-input v-model="schedulFormData.agentContact" @input="change">
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="10">
          <el-col :sm="12" :md="8">
            <el-form-item prop="surveyorCompany" label="Surveyor Company">
              <el-input v-model="schedulFormData.surveyorCompany" @input="change">
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :sm="12" :md="8">
            <el-form-item prop="surveyorContact" label="Surveyor Contact">
              <el-input v-model="schedulFormData.surveyorContact" @input="change">
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :sm="12" :md="8">
            <el-form-item prop="surveyorEmail" label="Surveyor Email" :rules="GENERAL_EMAIL_RULE">
              <el-input v-model="schedulFormData.surveyorEmail" @input="change">
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </base-detail-card>
      <base-detail-card title="Remarks">
        <el-row>
          <el-form-item prop="remark">
            <el-input type="textarea" :rows="2" v-model="schedulFormData.remark">
            </el-input>
          </el-form-item>
        </el-row>
      </base-detail-card>
      <!-- bnd info -->
      <!--      <base-detail-card title="BDN Information">-->
      <!--        <el-row :gutter="10">-->
      <!--          <el-col :span="8">-->
      <!--            <el-form-item-->
      <!--              prop="bdnOrgId"-->
      <!--              label="BDN">-->
      <!--              <el-select-->
      <!--                v-model="schedulFormData.bdnOrgId"-->
      <!--                style="width: 100%"-->
      <!--                placeholder="Select">-->
      <!--                <el-option-->
      <!--                  v-for="item in licenseNumbercompanys"-->
      <!--                  :key="item.licenseNumber"-->
      <!--                  :label="item.label"-->
      <!--                  :value="item.id">-->
      <!--                </el-option>-->
      <!--              </el-select>-->
      <!--            </el-form-item>-->
      <!--          </el-col>-->
      <!--        </el-row>-->
      <!--      </base-detail-card>-->
      <el-form>
        <base-detail-card v-if="jobData.id" title="Audit Trails">
          <audit-trails-information :jobId="jobData.id" />
        </base-detail-card>
        <base-detail-card title="MPA Transaction Information" v-if="jobData.status == 4" :actions="mpaInfoActions"  >
          <mpa-transaction-information
            :mpaTransactionData="mpaTransactionData"
          />
        </base-detail-card>
        <base-detail-card
          title="SgTradex Transaction Information"
          v-if="
            sgTradexState && jobData.status === 4 && sgTradexState.isSgTradexInfoVisible
          "
          :action="sgTradexState.actions"
        >
          <sg-tradex-transaction-information
            :sgTradexTransactionData.sync="sgTradexState.sgTradexTransactionData"
            :loading.sync="sgTradexState.sgTradexTransactionLoading"
          />
        </base-detail-card>
      </el-form>
    </el-form>
    <email-recipients
      v-if="jobData.id"
      :jobId="jobData.id"
      :jobStatus="jobData.status"
      :showDialog="showEmailRecipientDialog"
      @on-dialog-closed="() => (showEmailRecipientDialog = false)"
    />
  </div>
</template>

<script>
import {
  HEADER_CELL_STYLE,
  CELL_STYLE,
  GENERAL_EMAIL_RULE,
  TERM_CONTRACT_TYPE,
  SUPPLY_TYPE_OPTIONS,
  LICENSE_NUMBKER_RULE,
  DATETIME_FORMAT
} from "@/constants.js";
import BaseDetailCard from "@/components/BaseDetailCard";
import { mapActions, mapState } from "vuex";
import { sortByAlphabetical, getTodayISOString, isValidImoNo,sortListByRecent } from "@/utils";
import baseMixin from "@/mixins/base-mixin";
import { getAvailableBarges, getJobFiles,updateReferenceNo } from "@/services/modules/ops.js";
import {
  getAuthorizedBdnCompanies,getShipByShipId
} from "@/services/modules/comm.js";
import MpaTransactionInformation from "./MpaTransactionInformation.vue";
import SgTradexTransactionInformation from "./SgTradexTransactionInformation.vue";
import AuditTrailsInformation from "./AuditTrailsInformation.vue";
import JobFiles from "./JobFiles.vue";
import EmailRecipients from "./EmailRecipients.vue";
import FuelTypeRelatedInformation from "./FuelTypeRelatedInformation.vue"
import SearchVesselSelector from './searchVesselSelector.vue'
import BargeOrVesselSelector from './BargeOrVesselSelector.vue';
import TimeSheetInformation from './TimeSheetInformation.vue';
export default {
  props: {
    jobData: {
      type: Object,
      default: () => {},
    },
    locationOptionProps: Object,
    mpaTransactionData: Array,
    hasSysAdminPermission: Boolean,
    sgTradexState: {
      type: Object,
      required: true,
    },
  },
  mixins: [baseMixin],
  components: {
    BaseDetailCard,
    MpaTransactionInformation,
    AuditTrailsInformation,
    SgTradexTransactionInformation,
    JobFiles,
    EmailRecipients,
    FuelTypeRelatedInformation,
    SearchVesselSelector,
    BargeOrVesselSelector,
    TimeSheetInformation
  },
  data() {
    return {
      isJobCreatorOrBuyerEditView: true,
      HEADER_CELL_STYLE,
      CELL_STYLE,
      GENERAL_EMAIL_RULE,
      SUPPLY_TYPE_OPTIONS,
      LICENSE_NUMBKER_RULE,
      DATETIME_FORMAT,
      authorizedBdnCompanies: [],
      availableBargesOptions: [],
      availableBargesLoading: true,
      balanceData: "",
      dialogTableVisible: false,
      locationList: this.$store.state.locationList,
      submitLoading: false,
      cancelLoading: false,
      isEdit: false,
      isCanceled: false,
      jobDataInner: null,
      createNewVessel: false,
      emailDisabled: true,
      imoDisabled: true,
      schedulFormData: {
        type: 1, // 1: delivery 2: loading, 3: STS
        orderId: null,
        salesOrderNumber: null,
        buyerSysOrganizationId: null,
        stemStartDate: getTodayISOString(),
        stemEndDate: getTodayISOString(),
        sysProductId: null,
        sysProductSpecificationId: null,
        productName: null,
        minQty: null, // these 3 fields come from order
        maxQty: null,
        finalQty: null,
        nominationReference: null,
        customerName: null,
        vessel: null,
        vesselShipId: null,
        vesselLicenseNo: null,
        vesselName: null,
        vesselImo: null,
        vesselEmail: null,
        bargeOrgId: null, // for bdn logo,
        bargeShipId: null,
        bargeName: null,
        bargeImo: null,
        // bargeLicenceNo: null,
        vesselEta: null,
        locationLevel: [],
        agentCompany: "",
        agentContact: "",
        surveyorCompany: "",
        surveyorContact: "",
        surveyorEmail: "",
        qtyUnitMetric: "MT", // mt/cbm/bbl
        toleranceFigUnit: "MT",
        port: "Singapore",
        specs: null,
        orderNo: "",
        productId: null,
        sellerSysOrganizationId: null,
        tradeRecordId: "",
        tradeRecordOrigin: "",
        hasSurveyor: false,
        mitigatingCompanyOrgId: null,
        locationLevel1: "",
        locationLevel2: "",
        sulphur: "", // 0.10% S MAX / 0.50% S MAX / 1.50%S MAX / 3.50% S MAX/ 10PPM / 100PPM / 500PPM
        bargeSealVerificationReportId: "",
        remark: "",
        fuelTypeCode: null,
        supplyType: null,
        numberOfBdn: 1,
        bdnQuantities: [],
      },
      sulphurOptions: ["0.10% S Max", "0.50% S Max", "1.50% S Max", "3.50% S Max"],

      rules: {
        reqRule: [
          {
            required: true,
            message: "This field is required",
            trigger: ["change", "blur"],
          },
        ],
        minQty: [
          {
            required: false,
            validator: (rule, value, callback) =>
              this.nonNegativeQtyValidation(rule, value, callback, "Minimum Quantity"),
          },
        ],
        maxQty: [
          {
            required: false,
            validator: (rule, value, callback) =>
              this.nonNegativeQtyValidation(rule, value, callback, "Maximum Quantity"),
          },
        ],
        finalQty: [
          {
            required: true,
            message: "This field is required",
            trigger: ["change", "blur"],
          },
          {
            required: true,
            validator: (rule, value, callback) =>
              this.nonNegativeQtyValidation(rule, value, callback, "Final Quantity"),
          },
          { required: true, validator: this.finalQtyValidation },
        ],
        vesselImoNo: [
          {
            validator: (rule, value, callback) => {
              if (!(!value || value.trim() === "") && !isValidImoNo(value)) {
                callback("Invalid IMO No.");
              }
              callback();
            },
            trigger: ["change", "blur"],
          },
        ],
        bdnQuantity: [
          {
            required: true,
            message: "This field is required",
            trigger: ["change", "blur"],
          },
          {
            required: true,
            validator: (rule, value, callback) => {
              this.nonNegativeQtyValidation(rule, value, callback, "Bdn Quantity")
            }
              ,
          },
        ],
        supplyTypeValidate: [
          {
            required: true,
            message: "This field is required",
            trigger: ["change", "blur"],
          },
          {
            required: true,
            validator: (rule, value, callback) => {
              // validate Domestic
              let port = this.schedulFormData.port
              let domesticPort = ['singapore', 'sg', 'sgp'].includes(port.toLowerCase())
              if(domesticPort || value == 'D') {
                if(! domesticPort || value != 'D') {
                  callback(new Error("Supply Type must be Domestic if Port is Singpore, SG or SGP only"));
                }
              }
              callback();
            },
          },
        ]
      },

      //  所有状态为1，2的orderNumber
      orderTableData: [],
      orderLoading: false,
      multipleTable: [],
      selectedOrderProductSpec: null,
      selectedOrder: null,
      orderSearchKeyword: null,

      sysProductOptions: [],
      sysProductSpecificationOptions: [],

      orderPagination: {
        pageSize: 50,
        pageNumber: 0,
        total: 0,
      },
      isPageNumberAndSizeChange: false,
      mpaInfoActions: [],
      existingBdnCompanyId: null,
      jobFiles: {},
      showEmailRecipientDialog: false,
      searchedVesselList: [],
      cashType: "non-cash",
      tier4Price: 0,

      mpaFuelTypeOptions: [],
      isExxonMobil: false,
      receivingVesselMpaStatus:'',
      supplyingBargeMpaStatus:'',
      barge:{},

    };
  },
  computed: {
    ...mapState({
      currentCompany: "currentCompany",
      currentCompanySettings: "currentCompanySettings",
      companyList: "companyList",
      mpaFuelTypeList: "mpaFuelTypeList",
      creditAssociationList: "creditAssociationList",
      productList: "productList",
      physicalSupplierList: "physicalSupplierList",
    }),
    companyOpts() {
      return this.companyList.map((item) => ({
        value: item.id,
        label: item.companyName,
        licenseNumber: item.licenseNumber,
      }));
    },
    licenseNumbercompanys() {
      return this.companyOpts.filter((item) => item.licenseNumber);
    },

    withOrder() {
      return this.schedulFormData.orderId !== null && this.schedulFormData.orderId !== "";
    },
    selectedProduct() {
      if (this.withOrder) {
        return (
          this.selectedOrderProductSpecOptions.find(
            (p) => p.key === this.selectedOrderProductSpec
          ) || {}
        );
      } else {
        return (
          this.sysProductOptions.find(
            (p) => p.id === this.schedulFormData.sysProductId
          ) || {}
        );
      }
    },
    selectedBarge() {
      return (
        this.availableBargesOptions.find(
          (s) =>
            s.id === this.schedulFormData.bargeShipId ||
            (s.imo && s.imo === this.schedulFormData.bargeImo)
        ) || {}
      );
    },
    qtyBalance() {
      const finalQty = parseFloat(this.schedulFormData.finalQty);
      if (!finalQty) {
        return this.balanceData.qtyBalance;
      } else {
        return (this.balanceData.qtyBalance - finalQty).toFixed(5) * 1;
      }
    },
    qtyAssigned() {
      const finalQty = parseFloat(this.schedulFormData.finalQty);
      if (!finalQty) {
        return this.balanceData.qtyAssigned;
      } else {
        return (this.balanceData.qtyAssigned + finalQty).toFixed(5) * 1;
      }
    },
    physicalSupplierOptions() {
      if (this.withOrder) {
        const orderPhysicalSupplierIds = this.selectedOrderProductSpecOptions.map(
          (ps) => ps.physicalSupplierSysOrganizationId
        );
        return this.physicalSupplierList.filter((c) =>
          orderPhysicalSupplierIds.includes(c.id)
        );
      }

      if (this.isEdit) {
        const result = [...this.authorizedBdnCompanies];

        if (
          this.existingBdnCompanyId &&
          !this.authorizedBdnCompanies.find((c) => c.id === this.existingBdnCompanyId)
        ) {
          const existingBdnCompany = this.physicalSupplierList.find(
            (c) => c.id === this.existingBdnCompanyId
          );
          if (existingBdnCompany) result.push(existingBdnCompany);
        }
        return result;
      }
      return this.authorizedBdnCompanies;
    },

    getFuelCodeRules() {
      const rules = [
        { required: true, message: this.$t("rejectBeEmpty"), trigger: "change" },
      ];

      return rules;
    },
    nominationReferenceLabel() {
      return this.isExxonMobil ? "SAP Order Number" : "Nomination Reference"
    },
  },
  methods: {
    isValidImoNo,
    sortByAlphabetical,
    getAuthorizedBdnCompanies,
    sortListByRecent,

    getOrderById(orderId) {
      return new Promise((resolve, reject) => {
        this.$request
          .get({
            url: `${this.$apis.orders}/${orderId}`,
          })
          .then((res) => {

            if (res.code === 1000) {
              resolve(res.data);
            } else {
              reject(Error("error getting order"));
            }
          });
      });
    },


    getProductListSortByRecent (isVisit) {
      if (!this.schedulFormData?.bargeShipId ||this.schedulFormData?.bargeShipId?.trim()=='' ||!isVisit) return
      this.$request.get({
        url: `${this.$apis.recentProducts}?shipId=${this.schedulFormData.bargeShipId}`
      }).then(res => {
        if (res?.code === 1000 && res.data?.length>0) {
          const _arr =this.sortListByRecent(this.productList,res.data);
          this.sysProductOptions= _arr
        }
      })
    },
    getMpaFuelTypeListSortByRecent (isVisit) {
      if ( !isVisit ) return
      this.mpaFuelTypeOptions = [];
      const productItem =this.schedulFormData?.sysProductId ? this.sysProductOptions.find(product=>product.id == this.schedulFormData?.sysProductId):null
      if (productItem?.oilType.trim()==''|| !productItem?.oilType) {
        this.mpaFuelTypeOptions = [];
      }else{
        this.$store.dispatch("getFilterMpaFuelTypeList",{oilType:productItem.oilType,shipId:this.schedulFormData.bargeShipId}).then((data) => {
          this.mpaFuelTypeOptions = data
        })
      }
    },

    getSysProductSpecifications() {
      this.$request
        .get({
          url: this.$apis.productSpecifications,
        })
        .then((res) => {
          if (res.code === 1000) {
            this.sysProductSpecificationOptions = res.data;
            const defaultSpec = this.sysProductSpecificationOptions.find(
              (spec) => spec.name === "-"
            );
            if (!this.schedulFormData?.sysProductSpecificationId && defaultSpec) {
              this.schedulFormData.sysProductSpecificationId = defaultSpec.id;
            }
          }
        });
    },


    // 验证最大值
    finalQtyValidation(rule, value, callback) {
      if (this.withOrder) {
        if (!this.schedulFormData.sysProductId) {
          callback(new Error("Please select a product name first"));
        } else if (!this.balanceData) {
          callback(new Error("Balance Data from the API is invalid"));
        } else if (this.balanceData.qtyBalance === 0) {
          callback(new Error("Quantity Balance is 0"));
        } else if (Number(value) < 1 || Number(value) > this.balanceData.qtyBalance) {
          callback(
            new Error(
              `Final Quantity must be between 1 and ${this.balanceData.qtyBalance}`
            )
          );
        } else {
          callback();
        }
      } else {
        callback();
      }
    },
    nonNegativeQtyValidation(rule, value, callback, fieldName) {
      if (Number(value) < 0) {
        callback(new Error(`${fieldName || rule.field} must be a positive value`));
      }
      callback();
    },
    // 请求balance qty
    getBalance() {
      this.$request
        .get({
          url:
            this.$apis.getBalanceNumber +
            this.schedulFormData.orderId +
            "/productName/" +
            this.schedulFormData.sysProductId +
            "/specs/" +
            this.schedulFormData.sysProductSpecificationId +
            "/balanceInfo",
        })
        .then((res) => {
          if (res.code === 1000) {
            this.balanceData = res.data;
          }
        });
    },
    ConfirmDialogTableVisible(rows) {
      this.dialogTableVisible = false;
      if (this.selectedOrder) {
        this.changeOrder(this.selectedOrder);
      }
    },
    getLocationLevel(locationLevel2){
      if (!locationLevel2) return null;
      let locationLevel;
      for (let i = 0; i < this.locationList.length; i++) {
        if ( this.locationList[i].children.length > 0 ) {
          const childItem = this.locationList[i].children.find(c=>c.name == locationLevel2);
          if (childItem) {
            locationLevel = [this.locationList[i].name,locationLevel2]
            break;
          }
        }
      }
      return locationLevel;
    },
    //  link order发生变化时触发
    async changeOrder(selectedOrder) {
      this.clearOrder(false);
      debugger
      if (selectedOrder.salesOrderNo) {
        const order = await this.getOrderById(selectedOrder.id);
        this.selectedOrder = order;
        const agent = order.thirdParties.find((tp) => tp.type === "AGENT");
        const surveyor = order.thirdParties.find((tp) => tp.type === "SURVEYOR");
        this.schedulFormData.orderId = order.id;
        this.schedulFormData.salesOrderNumber = order.salesOrderNo;
        this.schedulFormData.buyerSysOrganizationId = order.buyerSysOrganizationId;
        this.schedulFormData.port = order.port;
        this.schedulFormData.stemStartDate = order.stemStartDate;
        this.schedulFormData.stemEndDate = order.stemEndDate;
        this.schedulFormData.vesselShipId = order?.vesselShipId;
        this.schedulFormData.vesselLicenseNo = order?.vesselLicenseNo;
        this.schedulFormData.vesselName = order.vesselName;
        this.schedulFormData.vesselImo = order?.vesselImoNo;

        this.schedulFormData.agentCompany = agent ? agent.company : null;
        this.schedulFormData.agentContact = agent ? agent.contact : null;
        this.schedulFormData.surveyorCompany = surveyor ? surveyor.company : null;
        this.schedulFormData.surveyorContact = surveyor ? surveyor.contact : null;
        this.schedulFormData.surveyorEmail = surveyor ? surveyor.email : null;
        this.schedulFormData.sellerSysOrganizationId = order.sellerSysOrganizationId;
        this.schedulFormData.buyerSysOrganizationId = order.buyerSysOrganizationId;
         // for victory feats in the order --start
        this.schedulFormData.bargeShipId = order?.bargeShipId;
        this.schedulFormData.bargeName = order.bunkerBargeName;
        this.schedulFormData.bargeImo = order?.bunkerBarge;
        const locationLevel =this.getLocationLevel(order.bunkerLocation)
        this.schedulFormData.locationLevel = locationLevel,
        this.schedulFormData.locationLevel1 = locationLevel?.length>0?locationLevel[0]:'';
        this.schedulFormData.locationLevel2 = order?.bunkerLocation;
        if (this.currentCompanySettings.pricingTierCheck) {
          this.schedulFormData.vesselEta = order?.orderDate;
        }
        // for victory feats in the order --end
        const uniqueProducts = [];
        selectedOrder.products
          .filter((p) => p.status === "PENDING")
          .filter((p) => p.hasBalance)
          .forEach((p) => {
            let index;
            uniqueProducts.length === 0
              ? (index = -1)
              : (index = uniqueProducts.findIndex(
                  (t) =>
                    t.sysProductId === p.sysProductId &&
                    t.sysProductSpecificationId === p.sysProductSpecificationId
                ));
            if (index <= -1) {
              uniqueProducts.push({
                key: `${p.sysProductId}|${p.sysProductSpecificationId}`,
                sysProductId: p.sysProductId,
                sysProductName: p.sysProductName,
                sysProductSpecificationId: p.sysProductSpecificationId,
                sysProductSpecificationName: p.sysProductSpecificationName,
                physicalSupplierSysOrganizationId: p.physicalSupplierSysOrganizationId,
                physicalSupplierName: p.physicalSupplierName,
              });
            }
          });

        this.selectedOrderProductSpecOptions = uniqueProducts;
      }

      this.$forceUpdate();
    },
    changeProduct(sysProductId) {
      this.schedulFormData.fuelTypeCode = null;
      if (this.withOrder && sysProductId) {
        this.schedulFormData.sysProductId = this.selectedProduct.sysProductId;
        this.schedulFormData.productName = this.selectedProduct.sysProductName;
        this.schedulFormData.sysProductSpecificationId = this.selectedProduct.sysProductSpecificationId;
        this.schedulFormData.specs = this.selectedProduct.sysProductSpecificationName;
        this.getBalance();
        this.schedulFormData.bargeOrgId = this.selectedOrderProductSpecOptions.find(
          (p) => p.key === sysProductId
        )?.physicalSupplierSysOrganizationId;
        this.handleChangeBDN(this.schedulFormData.bargeOrgId)
      } else {
        this.schedulFormData.sysProductId = sysProductId;
        this.schedulFormData.productName = this.sysProductOptions.find(
          (product) => product.id === sysProductId
        )?.name;
      }
    },
    changeProductSpecification(specificationId) {
      if (this.withOrder) {
        return;
      }

      this.schedulFormData.sysProductSpecificationId = specificationId;
      this.schedulFormData.specs = this.sysProductSpecificationOptions.find(
        (specification) => specification.id === specificationId
      )?.name;
    },
    handleChangeBarge(selectedBargeId) {
      if (selectedBargeId) {
        this.schedulFormData.bargeShipId = selectedBargeId;
        const barges = this.availableBargesOptions
        const shipItem = barges
          ? this.availableBargesOptions.find((ship) => ship?.id === selectedBargeId)
          : {};

        this.schedulFormData.bargeName = shipItem?.shipName;
        this.schedulFormData.bargeImo = shipItem?.imo;
        this.schedulFormData.bargeLicenceNo = shipItem?.licenceNo;
      }
    },

    submitForm() {
      // if (this.selectedBarge && !(this.schedulFormData.bargeImo || this.schedulFormData.bargeLicenceNo)) {
      //   this.$message.error('Licence no. and IMO of the selected barge can not be blank at the same time')
      //   return
      // }
      // if (this.selectedBarge && !(this.schedulFormData.vesselImo || this.schedulFormData.vesselLicenseNo)) {
      //   this.$message.error('Licence no. and IMO of the selected vessel can not be blank at the same time')
      //   return
      // }

      if (this.cashType == "cash" && this.currentCompanySettings.pricingTierCheck) {
        this.schedulFormData.buyerSysOrganizationId = null;
        this.schedulFormData.sellerSysOrganizationId = null;
      } else if (!this.schedulFormData.buyerSysOrganizationId) {
        this.schedulFormData.sellerSysOrganizationId = null;
      }

      this.$refs.schedulForm.validate((valid) => {
        if (valid) {
          this.sendFormData();
        } else {
          this.$message.error("Error. Required fields not filled in.");
          return false;
        }
      });
    },
    async sendFormData() {
      const getRequestData = () => {
        if (!this.withOrder && this.schedulFormData.buyerSysOrganizationId) {
          this.schedulFormData.sellerSysOrganizationId = this.currentCompany.id;
        }
        const locationLevel1 =
          this.schedulFormData.locationLevel && this.schedulFormData.locationLevel[0]
            ? this.schedulFormData.locationLevel[0]
            : "";
        const locationLevel2 =
          this.schedulFormData.locationLevel && this.schedulFormData.locationLevel[1]
            ? this.schedulFormData.locationLevel[1]
            : "";
        const requestData = Object.assign({}, this.schedulFormData, {
          vesselEta: this.schedulFormData.vesselEta
            ? new Date(this.schedulFormData.vesselEta).toISOString()
            : null,
          stemStartDate: this.schedulFormData.stemStartDate
            ? new Date(this.schedulFormData.stemStartDate).toISOString()
            : null,
          stemEndDate: this.schedulFormData.stemEndDate
            ? new Date(this.schedulFormData.stemEndDate).toISOString()
            : null,
          locationLevel1,
          locationLevel2,
          finalQty: Number(this.schedulFormData.finalQty),
          vesselId: null,
        });
        requestData.hasSurveyor =
          !!this.schedulFormData?.surveyorCompany ||
          !!this.schedulFormData?.surveyorContact ||
          !!this.schedulFormData?.surveyorEmail;
        return requestData;
      };

      this.submitLoading = true;

      const request = this.isEdit
        ? this.$request.put({
            url: this.$apis.editDeliveredJob,
            data: getRequestData(),
          })
        : this.$request.post({
            url: this.$apis.createNewJob,
            data: getRequestData(),
          });

      request
        .then((data) => {
          if (data?.code === 1000) {
            this.$message.success(data.message);
            this.$emit("close");
          } else {
            this.$message.error(data.message);
          }
        })
        .finally(async () => {
          this.submitLoading = false;
        });
    },
    submitReferenceNo () {
      this.submitLoading = true;
      updateReferenceNo(this.jobData.id,{referenceNumber: this.schedulFormData.referenceNo}).then((resp) => {
        if (resp?.code === 1000) {
          this.$message.success(resp.message);
          this.$emit("close");
        } else {
          this.$message.error(resp.message);
        }
      })
      .finally(async () => {
        this.submitLoading = false;
      });
    },
    handleCancelJob() {
      this.$confirm("", "Confirm cancel?", {
        confirmButtonText: this.$t("confirm"),
        cancelButtonText: this.$t("cancel"),
        type: "warning",
        center: true,
        roundButton: true
      }).then(() => {
        this.cancelLoading = true
        const url = `ebdn/jobs/${this.jobData.id}/cancel`
        this.$request.put({
          url
        }).then(res => {
          if (res?.code === 1000) {
            this.isCanceled = true
            this.$message.success('Job cancelled successfully')
            this.$emit('close')
          }
        }).finally(() => {
          this.cancelLoading = false
        })
      }).catch(() => {})
    },
    getJobInfoByJobId (jobId) {
      const url = `${this.$apis.createNewJob}/${jobId}/info`

      this.$request.get({
        url
      }).then(res => {
        if (res?.code === 1000) {
          this.jobDataInner = {
            ...res.data
          }
          this.mpaInfoActions = []
          let mpaPdfIds = (this.jobDataInner.mpaPdfIds?.length ?? 0) > 1 ? this.jobDataInner.mpaPdfIds : (this.jobDataInner.mpaPdfId ? [this.jobDataInner.mpaPdfId] : [])
          let length = mpaPdfIds.length ?? 0
          if (length) {
            mpaPdfIds.map( (mpaPdfId, index) => {
              this.mpaInfoActions.push({
                label: 'View EBDN File' + (length > 1 ? ' ' + (index + 1) : ''),
                onAction: () => {
                  this.previewFileMixin(mpaPdfId)
                }
              })
            })
          }

        }
      })
    },

    handleOrderPageChange(pageNumber) {
      if (!this.isPageNumberAndSizeChange) {
        this.getOrders(this.orderPagination.pageSize, pageNumber - 1);
      }
    },
    handleOrderPageSizeChange(pageSize) {
      this.isPageNumberAndSizeChange = true;
      this.getOrders(pageSize, 0);
    },
    getOrders(pageSize, pageNumber) {
      const baseSearchCriteria = `sellerSysOrganizationId :'${this.currentCompany.id}' and orderType in ('DELIVERED','BARGING_NOMINATION') and sellerOrderStatus :'CONFIRMED' and exists(products.hasBalance:true and products.status:'PENDING')`;
      const baseSortCriteria = "createdAt desc";
      const queryString = `pageSize=${pageSize}&pageNumber=${pageNumber}&filter=${baseSearchCriteria}${
        this.orderSearchKeyword?.trim()
          ? `and vesselName ~'*${this.orderSearchKeyword}*'`
          : ""
      }&sort=${baseSortCriteria}`;

      this.orderLoading = true;
      this.$request
        .get({
          url: `${this.$apis.orders}?${queryString}`,
        })
        .then((res) => {
          if (res.code === 1000) {
            this.orderTableData = res.data.content;
            this.orderPagination.total = res.data.totalElements;
            this.orderPagination.pageNumber = res.data.pageable.pageNumber + 1;
            this.isPageNumberAndSizeChange = false;
            this.orderLoading = false;
          }
        });
    },
    change() {
      this.$forceUpdate();
    },
    clearOrder(clearSelectedOrder) {
      if (clearSelectedOrder) {
        this.selectedOrder = null;
      }
      this.schedulFormData.orderId = null;
      this.schedulFormData.salesOrderNumber = null;
      this.schedulFormData.stemStartDate = null;
      this.schedulFormData.stemEndDate = null;
      this.schedulFormData.vesselShipId = null;
      this.schedulFormData.vesselLicenseNo = null;
      this.schedulFormData.vesselName = null;
      this.schedulFormData.vesselImo = null;
      this.schedulFormData.vesselEmail = null;
      this.schedulFormData.sysProductId = null;
      this.schedulFormData.productName = null;
      this.schedulFormData.sysProductSpecificationId = null;
      this.schedulFormData.specs = null;
      this.schedulFormData.loadingQty = "";
      this.schedulFormData.port = null;
      this.schedulFormData.sellerSysOrganizationId = null;
      this.schedulFormData.buyerSysOrganizationId = null;
      this.schedulFormData.bargeOrgId = null;
      this.schedulFormData.nominationReference = null;
      this.schedulFormData.customerName = null,
      this.selectedOrderProductSpecOptions = [];
      this.balanceData = "";
      this.selectedOrderProductSpec = null;
      this.schedulFormData.supplyType = null;
    },

    reqCheckAvailableBargesOptions(parmas) {
      getAvailableBarges(parmas)
        .then((res) => {
          this.availableBargesLoading = false;
          if (res?.code === 1000) {
            this.availableBargesOptions = sortByAlphabetical(
              res.data || [],
              "shipName"
            ).filter(
              (ship) =>
                !ship.charterId ||
                (ship.charterId && ship.charterId === this.currentCompany.id)
            );
          }
        })
        .catch((err) => (this.availableBargesLoading = false));
    },
    visibleChange(isVisit) {
      if (isVisit) {
        /*
          only Barging spot order (COA TC) check by termContractId
        */
        const termContractId = this?.selectedOrder?.termContract?.id;
        const termContractType = this?.selectedOrder?.termContract?.termContractType;
        const startDate = this.schedulFormData?.stemStartDate;
        const endDate = this.schedulFormData?.stemEndDate;
        if (startDate && endDate) {
          if (
            [TERM_CONTRACT_TYPE.TC, TERM_CONTRACT_TYPE.COA].includes(termContractType) &&
            termContractId
          ) {
            this.reqCheckAvailableBargesOptions({ termContractId, startDate, endDate });
          } else {
            const operatorSysOrganizationId =
              this?.selectedOrder?.sellerSysOrganizationId ?? this.currentCompany.id;
            this.reqCheckAvailableBargesOptions({
              operatorSysOrganizationId,
              startDate,
              endDate,
            });
          }
        } else {
          const title = "Get Available Barges Faild";
          const errMsg = `Require to fill in ${!startDate ? "Stem Start Date" : ""} ${
            !startDate && !endDate ? "and" : ""
          } ${endDate ? "Stem End Date" : ""}`;
          this.openErrMsg(errMsg, title);
        }
      }
    },
    openErrMsg(errMsg, title) {
      this.$alert(`${errMsg}, please check and try again.`, `${title}`, {
        confirmButtonText: "Close",
      });
    },
    numberWithCommas(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },

    handleSelectVessel(selectionId) {
      const selection = this.searchedVesselList.find((v) => v.id == selectionId);
      this.schedulFormData.vesselShipId = selection?.id;
      this.schedulFormData.vesselLicenseNo = selection?.licenceNo;
      this.schedulFormData.vesselName = selection?.shipName;
      this.schedulFormData.vesselImo = selection?.imo;
      this.schedulFormData.vesselEmail = selection?.emailRecipients;
      const buyerOrg = this.creditAssociationList.find((association) => association.counterpartySysOrganizationId == selection?.tenantId || association.id ==selection?.charterId);

      if (buyerOrg&&
        this.currentCompanySettings.canFilterSearchByCompanyId &&
        this.cashType == "non-cash" &&
        (selection?.tenantId || selection?.charterId)
      ) {
        this.schedulFormData.buyerSysOrganizationId =
          selection?.charterId ?? selection?.tenantId;
      }
    },
    async handleChangeBDN(bargeOrgId) {
      if(bargeOrgId) {
        await this.$store.dispatch("isExxonMobil", bargeOrgId).then(res => this.isExxonMobil = res)
      } else {
        this.isExxonMobil = false
      }
      if(! this.isExxonMobil ) {
        this.schedulFormData.customerName = null
      }
    },
    handleChangeNumberOfBdn() {
      const numberOfBdn = this.schedulFormData.numberOfBdn
      if( numberOfBdn == 1 ) {
        this.schedulFormData.bdnQuantities = []
      } else {
        const length =  numberOfBdn - this.schedulFormData.bdnQuantities.length
        for(var i = 0 ; i < Math.abs(length); i++) {
          if(length > 0) {
            this.schedulFormData.bdnQuantities.push(0)
          } else {
            this.schedulFormData.bdnQuantities = this.schedulFormData.bdnQuantities.slice(0,-1)
          }
        }
      }
    },

    async getSupplyingBargeMpaStatus() {
      const ship = await getShipByShipId(this.schedulFormData.bargeShipId)
      this.barge = ship;
      if (!ship) return "Not Found";
      if (ship.lastSyncedMpaBargeFound === null) return "Not Check";
      return ship.lastSyncedMpaBargeFound ? "Exist In MPA" : "Not In MPA";
    },

    async getReceivingVesselMpaStatus() {
      const ship = await getShipByShipId(this.schedulFormData.vesselShipId)
      if (!ship) return "Not Found";
      if (ship.lastSyncedMpaVesselFound === null) return "Not Check";
      return ship.lastSyncedMpaVesselFound ? "Exist In MPA" : "Not In MPA";
    },

  },
  destroyed() {
    this.$emit("onClearInterval");
  },
  async mounted() {
    this.$store.dispatch("getMpaFuelTypeList");
    this.$store.dispatch('getProductList')
    if (this.jobData && Object.keys(this.jobData).length > 0) {
      this.isEdit = true;
      this.existingBdnCompanyId = this.jobData.bargeOrgId;
      this.isCanceled = this.jobData.status === 5 || this.jobData.status === 4;
      this.schedulFormData = Object.assign({}, this.jobData, {
        locationLevel: [this.jobData.locationLevel1, this.jobData.locationLevel2],
      });

      this.selectedOrderProductSpec = `${this.jobData.sysProductId}|${this.jobData.sysProductSpecificationId}`;
      this.schedulFormData = { ...this.jobData };
      this.schedulFormData.vesselEta = this.jobData.vesselEta
        ? new Date(this.jobData.vesselEta)
        : null;
      this.getJobInfoByJobId(this.jobData.id);
      if ([3, 4].includes(this.jobData.status)) {
        getJobFiles(this.jobData.id).then((res) => {
          this.jobFiles = res.data;
        });
      }

      this.visibleChange(true);
      /// only job mitigating Company can edit
      this.isJobCreatorOrBuyerEditView = this.currentCompany.id === this.jobData.mitigatingCompanyOrgId
      this.handleChangeBDN(this.jobData.bargeOrgId)
      this.cashType = this.schedulFormData.buyerSysOrganizationId ? "non-cash" : "cash";

      try {this.supplyingBargeMpaStatus = await this.getSupplyingBargeMpaStatus() } catch (error) {}
      try {this.tier4Price = await this.cashPricingTier(this.schedulFormData.createdAt,this.currentCompany.id);} catch (error) {}
      try {this.receivingVesselMpaStatus = await this.getReceivingVesselMpaStatus()} catch (error) {}

    } else {
      this.schedulFormData.mitigatingCompanyOrgId = this.currentCompany.id;
      this.tier4Price = await this.cashPricingTier(null,this.currentCompany.id);
    }
    this.sysProductOptions =this.productList;
    this.mpaFuelTypeOptions =this.mpaFuelTypeList;
    this.getSysProductSpecifications();
    this.getOrders(this.orderPagination.pageSize, this.orderPagination.pageNumber);
    this.getAuthorizedBdnCompanies(
      this.isEdit ? this.schedulFormData.mitigatingCompanyOrgId : this.currentCompany.id
    ).then((res) => {
      if (res?.code === 1000) {
        this.authorizedBdnCompanies = res.data ?? [];
      }
    });
    this.$store.dispatch('getPhysicalSupplierList')



    if (this.withOrder) {
      this.getOrderById(this.schedulFormData.orderId).then((order) => {
        this.selectedOrder = order;
      });
    }

    this.$store.dispatch('getCreditAssociationList')
  },
  watch: {
    "currentCompany.id": {
      handler(value) {
        if (value) {
          setTimeout(() => {
            this.$store.dispatch("getCompanySettings", value);
          }, 1000);
        }
      },
      immediate: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.schedul-detail {
  height: 100%;

  .main-scroll-form {
    height: calc(100% - 42px);
    overflow-y: auto;
    padding-right: 10px;

    .classification {
      width: 100%;
      margin: 5px auto;
      p {
        margin-top: 0;
        font-weight: bold;
        color: #606266;
      }
      /* sub-module-title */
      .title {
        font-family: "Roboto";
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        color: $second-color-font;
        margin: 0;
        padding: 10px 10px 0 10px;
        width: fit-content;
        border-radius: 5px;
      }

      .product-container {
        width: 100%;
        display: flex;

        /deep/ input,
        /deep/ .el-date-editor,
        /deep/ textarea,
        /deep/ .el-input-group__append,
        /deep/ .el-input-group__prepend {
          border: none;
        }

        .delete-btn-block {
          margin-right: 20px;

          > div {
            display: flex;
            align-items: flex-end;
            height: 62px;

            &:first-child {
              height: 84px;
              margin-top: 20px;
            }
          }
        }
      }

      .single-row-container {
        width: calc(100% - 40px);
        box-sizing: border-box;
        overflow-x: auto;
        margin: 0 10px;

        > div {
          display: flex;

          .el-form-item {
            min-width: 150px;
          }
          .delete-btn-block {
            position: absolute;
            right: -30px;
            bottom: 0;
          }
        }
      }

      .delete-btn-item {
        padding: 3px;
      }

      .el-row {
        padding: 10px;

        .cost {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          margin: 10px 0;
        }
      }
    }

    .product {
      /deep/ input,
      /deep/ .el-date-editor,
      /deep/ textarea,
      /deep/ .el-input-group__append,
      /deep/ .el-input-group__prepend {
        border: none;
      }

      .single-border {
        /deep/ input {
          border: 1px solid #dcdfe6 !important;
        }
      }
      .div-single-border {
        border-radius: 0;
        border-right: 1px solid #dcdfe680 !important;
      }
      /deep/ .no-border {
        input {
          border: none !important;
        }
      }
    }

    .base-detail-card {
      .container {
        height: calc(100% - 42px);

        .el-form {
          padding-right: 10px;
        }

        .contract-number {
          display: flex;
          justify-content: space-between;

          .num-item {
            display: inline-flex;
            flex-direction: column;
            margin: 0 30px 20px 0;

            /deep/ .el-input {
              margin-top: 10px;
              font-size: 18px;

              /deep/ input {
                color: $primary-color;
              }
            }

            .el-form-item {
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }

  .btn-container {
    text-align: right;
    margin-right: 20px;
  }
  .input-with-select {
    /deep/ .el-select .el-input {
      width: 90px;
    }

    /deep/ .el-input-group__append,
    /deep/ .el-input-group__prepend {
      background-color: #fff;
    }
    .el-select {
      /deep/ .el-input__inner {
        border: none !important;
      }
    }
  }
  .product-items {
    margin-bottom: 10px;
  }
}

/deep/.el-dialog {
  width: 800px;
}
/deep/.el-table .cell {
  white-space: pre-line;
}
.BalanceDiv {
  width: 100%;
  height: 60px;
  line-height: 20px;
  padding: 0 30px 0 20px;
  box-sizing: border-box;
  & > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
/deep/.el-dialog {
  height: 750px;
  width: 1610px;
}
/deep/.el-dialog__body {
  height: 550px;
}
input[type="number"] {
  -moz-appearance: textfield;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

/deep/.el-dialog__body {
  padding-top: 0;
  padding-bottom: 30px;
}
/deep/.el-dialog__footer {
  margin-top: 30px !important;
}
.el-date-editor {
  width: 100%;
}
.search-vessel-suffix-hidden {
  /deep/.el-input__suffix {
    visibility: hidden !important;
  }
}
</style>
