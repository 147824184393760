var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "schedul-detail" },
    [
      _c(
        "div",
        { staticClass: "btn-container" },
        [
          ![4, 5, 6].includes(_vm.jobData.status) &&
          _vm.jobData.status &&
          !_vm.isCanceled &&
          _vm.hasPermission(_vm.$permissionFunctionCode.EDIT) &&
          _vm.isJobCreatorOrBuyerEditView
            ? _c(
                "el-button",
                {
                  staticStyle: { "min-width": "100px" },
                  attrs: {
                    type: "danger",
                    size: "small",
                    round: "",
                    loading: _vm.cancelLoading,
                  },
                  on: { click: _vm.handleCancelJob },
                },
                [_vm._v(" Cancel Job ")]
              )
            : _vm._e(),
          ([1, 2].includes(_vm.jobData.status) || !_vm.isEdit) &&
          _vm.isJobCreatorOrBuyerEditView
            ? _c(
                "el-button",
                {
                  staticStyle: { "min-width": "100px" },
                  attrs: {
                    type: "primary",
                    size: "small",
                    round: "",
                    loading: _vm.submitLoading,
                  },
                  on: { click: _vm.submitForm },
                },
                [_vm._v(" Submit ")]
              )
            : _vm._e(),
          _vm.jobData.status == 4 && _vm.isJobCreatorOrBuyerEditView
            ? _c(
                "el-button",
                {
                  staticStyle: { "min-width": "100px" },
                  attrs: {
                    type: "primary",
                    size: "small",
                    round: "",
                    loading: _vm.submitLoading,
                  },
                  on: { click: _vm.submitReferenceNo },
                },
                [_vm._v(" Submit Reference No ")]
              )
            : _vm._e(),
          _c(
            "el-button",
            {
              staticStyle: { "min-width": "100px" },
              attrs: { outline: "", size: "small", round: "" },
              on: {
                click: function ($event) {
                  return _vm.$emit("close", true)
                },
              },
            },
            [_vm._v(" Close ")]
          ),
        ],
        1
      ),
      _c(
        "el-form",
        {
          ref: "schedulForm",
          staticClass: "main-scroll-form",
          attrs: {
            model: _vm.schedulFormData,
            disabled:
              _vm.isCanceled ||
              [3, 4, 5, 6].includes(_vm.jobData.status) ||
              !_vm.isJobCreatorOrBuyerEditView,
            "label-position": "top",
            "validate-on-rule-change": false,
          },
        },
        [
          _vm.jobData.status && [3, 4, 6].includes(_vm.jobData.status)
            ? _c(
                "base-detail-card",
                { attrs: { title: "Files" } },
                [
                  _c("job-files", {
                    attrs: { jobFiles: _vm.jobFiles },
                    on: {
                      "update:jobFiles": function ($event) {
                        _vm.jobFiles = $event
                      },
                      "update:job-files": function ($event) {
                        _vm.jobFiles = $event
                      },
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.isEdit
            ? _c(
                "base-detail-card",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: !_vm.jobDataInner,
                      expression: "!jobDataInner",
                    },
                  ],
                  attrs: { title: "Delivered Information" },
                },
                [
                  _c(
                    "el-form",
                    {
                      ref: "deliveredScheduleForm",
                      attrs: {
                        model: _vm.jobDataInner,
                        "label-position": "top",
                        "validate-on-rule-change": false,
                      },
                    },
                    [
                      _c(
                        "el-row",
                        { attrs: { gutter: 10 } },
                        [
                          _c(
                            "el-col",
                            { attrs: { sm: 12, md: 8, lg: 4 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "Product Name" } },
                                [
                                  _c("el-input", {
                                    staticClass: "single-border",
                                    attrs: {
                                      readonly: "",
                                      value: (_vm.jobDataInner || {})
                                        .productName,
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { sm: 12, md: 8, lg: 4 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "Barge Name" } },
                                [
                                  _c("el-input", {
                                    staticClass: "single-border",
                                    attrs: {
                                      readonly: "",
                                      value: (_vm.jobDataInner || {}).bargeName,
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { sm: 12, md: 8, lg: 4 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: { label: "eBDN Date" },
                                },
                                [
                                  _c("el-date-picker", {
                                    staticClass: "single-border",
                                    attrs: { type: "date", readonly: "" },
                                    model: {
                                      value: (_vm.jobDataInner || {}).docDate,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.jobDataInner || {},
                                          "docDate",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "(jobDataInner || {}).docDate",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { sm: 12, md: 8, lg: 4 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "Delivered Quantity" } },
                                [
                                  _c("el-input", {
                                    staticClass: "single-border",
                                    attrs: {
                                      readonly: "",
                                      value: _vm.numericByCompanySetting(
                                        (_vm.jobDataInner || {}).docQty
                                      ),
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { sm: 12, md: 8, lg: 4 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "ETC" } },
                                [
                                  _c("el-date-picker", {
                                    staticClass: "single-border",
                                    attrs: { type: "date", readonly: "" },
                                    model: {
                                      value: (_vm.jobDataInner || {}).etc,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.jobDataInner || {},
                                          "etc",
                                          $$v
                                        )
                                      },
                                      expression: "(jobDataInner || {}).etc",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { sm: 12, md: 8, lg: 4 } },
                            [
                              (_vm.jobDataInner || {}).bdnPdfId
                                ? _c(
                                    "el-form-item",
                                    { attrs: { label: "BDN Document" } },
                                    [
                                      _c(
                                        "el-button",
                                        {
                                          staticStyle: { width: "100%" },
                                          attrs: { type: "primary", plain: "" },
                                          on: {
                                            click: function ($event) {
                                              _vm.previewFileMixin(
                                                (_vm.jobDataInner || {})
                                                  .bdnPdfId
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                (_vm.jobDataInner || {})
                                                  .docNumber
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm.jobDataInner?.timesheet
                        ? _c("time-sheet-information", {
                            attrs: {
                              jobDataInner: _vm.jobDataInner,
                              jobData: _vm.jobData,
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c("base-detail-card", { attrs: { title: "Product Information" } }, [
            _c(
              "div",
              [
                !_vm.isEdit
                  ? _c(
                      "el-row",
                      { attrs: { gutter: 10 } },
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 8 } },
                          [
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  type: "outlined",
                                  size: "mini",
                                  round: "",
                                  plain: "",
                                },
                                on: {
                                  click: function ($event) {
                                    _vm.dialogTableVisible = true
                                  },
                                },
                              },
                              [_vm._v("Select Order")]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "el-row",
                  { attrs: { gutter: 10 } },
                  [
                    _c(
                      "el-col",
                      { attrs: { sm: 24, md: 12, lg: 8 } },
                      [
                        !_vm.isEdit
                          ? _c(
                              "el-form-item",
                              {
                                attrs: {
                                  prop: "orderId",
                                  label: "Linked Order",
                                },
                              },
                              [
                                _c("el-input", {
                                  staticStyle: { width: "90%" },
                                  attrs: { disabled: "" },
                                  model: {
                                    value: _vm.schedulFormData.salesOrderNumber,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.schedulFormData,
                                        "salesOrderNumber",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "schedulFormData.salesOrderNumber",
                                  },
                                }),
                                _c(
                                  "el-dialog",
                                  {
                                    attrs: {
                                      title: "Link Order",
                                      visible: _vm.dialogTableVisible,
                                    },
                                    on: {
                                      "update:visible": function ($event) {
                                        _vm.dialogTableVisible = $event
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      [
                                        _c(
                                          "el-input",
                                          {
                                            staticClass: "round-input",
                                            attrs: {
                                              placeholder: "Search Vessel Name",
                                            },
                                            nativeOn: {
                                              keypress: function ($event) {
                                                if (
                                                  !$event.type.indexOf("key") &&
                                                  _vm._k(
                                                    $event.keyCode,
                                                    "enter",
                                                    13,
                                                    $event.key,
                                                    "Enter"
                                                  )
                                                )
                                                  return null
                                                return _vm.getOrders(
                                                  _vm.orderPagination.pageSize,
                                                  0
                                                )
                                              },
                                            },
                                            model: {
                                              value: _vm.orderSearchKeyword,
                                              callback: function ($$v) {
                                                _vm.orderSearchKeyword = $$v
                                              },
                                              expression: "orderSearchKeyword",
                                            },
                                          },
                                          [
                                            _c("el-button", {
                                              attrs: {
                                                slot: "append",
                                                type: "primary",
                                                icon: "el-icon-search",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.getOrders(
                                                    _vm.orderPagination
                                                      .pageSize,
                                                    0
                                                  )
                                                },
                                              },
                                              slot: "append",
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-table",
                                      {
                                        directives: [
                                          {
                                            name: "loading",
                                            rawName: "v-loading",
                                            value: _vm.orderLoading,
                                            expression: "orderLoading",
                                          },
                                        ],
                                        ref: "multipleTable",
                                        attrs: {
                                          data: _vm.orderTableData,
                                          height: "550px",
                                        },
                                      },
                                      [
                                        _c("el-table-column", {
                                          attrs: { width: "55" },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function (scope) {
                                                  return [
                                                    _c(
                                                      "el-radio",
                                                      {
                                                        attrs: {
                                                          label: scope.row,
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.selectedOrder,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.selectedOrder =
                                                              $$v
                                                          },
                                                          expression:
                                                            "selectedOrder",
                                                        },
                                                      },
                                                      [_c("i")]
                                                    ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            false,
                                            2705819809
                                          ),
                                        }),
                                        _c("el-table-column", {
                                          attrs: {
                                            prop: "vesselName",
                                            label: "Vessel Name",
                                            width: "150",
                                          },
                                        }),
                                        _c("el-table-column", {
                                          attrs: {
                                            prop: "orderType",
                                            label: "Order Type",
                                            width: "150",
                                          },
                                        }),
                                        _c("el-table-column", {
                                          attrs: {
                                            prop: "salesOrderNo",
                                            label: "Order Number",
                                            width: "170",
                                          },
                                        }),
                                        _c("el-table-column", {
                                          attrs: {
                                            prop: "buyerCompanyName",
                                            label: "Buyer ",
                                            width: "200",
                                          },
                                        }),
                                        _c("el-table-column", {
                                          attrs: {
                                            prop: "sellerCompanyName",
                                            label: "Seller",
                                            width: "200",
                                          },
                                        }),
                                        _c("el-table-column", {
                                          attrs: {
                                            prop: "products",
                                            label: "Product",
                                            width: "220",
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function (scope) {
                                                  return _vm._l(
                                                    scope.row.products,
                                                    function (item, index) {
                                                      return _c(
                                                        "div",
                                                        { key: index },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                item.sysProductName
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      )
                                                    }
                                                  )
                                                },
                                              },
                                            ],
                                            null,
                                            false,
                                            2683966735
                                          ),
                                        }),
                                        _c("el-table-column", {
                                          attrs: {
                                            prop: "products",
                                            label: "Nomination Qty",
                                            width: "200",
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function (scope) {
                                                  return _vm._l(
                                                    scope.row.products,
                                                    function (item, index) {
                                                      return _c(
                                                        "div",
                                                        { key: index },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.numberWithCommas(
                                                                  Number(
                                                                    item.finalQuantity
                                                                  ).toFixed(3)
                                                                )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      )
                                                    }
                                                  )
                                                },
                                              },
                                            ],
                                            null,
                                            false,
                                            811619264
                                          ),
                                        }),
                                        _c("el-table-column", {
                                          attrs: {
                                            prop: "products",
                                            label: "Verified Delivered Qty",
                                            width: "200",
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function (scope) {
                                                  return _vm._l(
                                                    scope.row.products,
                                                    function (item, index) {
                                                      return _c(
                                                        "div",
                                                        { key: index },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                item.verifiedQuantity
                                                                  ? _vm.numberWithCommas(
                                                                      Number(
                                                                        item.verifiedQuantity
                                                                      ).toFixed(
                                                                        3
                                                                      )
                                                                    )
                                                                  : "-"
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      )
                                                    }
                                                  )
                                                },
                                              },
                                            ],
                                            null,
                                            false,
                                            696890212
                                          ),
                                        }),
                                        _c("el-table-column", {
                                          attrs: {
                                            prop: "products",
                                            label: "Physical Supplier",
                                            width: "150",
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function (scope) {
                                                  return _vm._l(
                                                    scope.row.products,
                                                    function (item, index) {
                                                      return _c(
                                                        "div",
                                                        { key: index },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                item.physicalSupplierName
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      )
                                                    }
                                                  )
                                                },
                                              },
                                            ],
                                            null,
                                            false,
                                            1243151932
                                          ),
                                        }),
                                        _c("el-table-column", {
                                          attrs: {
                                            prop: "remarks",
                                            label: "Remarks ",
                                            width: "260",
                                            "show-overflow-tooltip": "",
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function (scope) {
                                                  return [
                                                    _c("div", [
                                                      _vm._v(
                                                        _vm._s(
                                                          scope.row.remarks ||
                                                            "-"
                                                        )
                                                      ),
                                                    ]),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            false,
                                            626088621
                                          ),
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "pagination-container" },
                                      [
                                        _c("el-pagination", {
                                          attrs: {
                                            background: "",
                                            "page-size":
                                              _vm.orderPagination.pageSize,
                                            "page-sizes": [5, 10, 20, 50, 100],
                                            "current-page":
                                              _vm.orderPagination.pageNumber,
                                            layout:
                                              "total, sizes, prev, pager, next",
                                            total: _vm.orderPagination.total,
                                          },
                                          on: {
                                            "size-change":
                                              _vm.handleOrderPageSizeChange,
                                            "current-change":
                                              _vm.handleOrderPageChange,
                                            "update:pageSize": function (
                                              $event
                                            ) {
                                              return _vm.$set(
                                                _vm.orderPagination,
                                                "pageSize",
                                                $event
                                              )
                                            },
                                            "update:page-size": function (
                                              $event
                                            ) {
                                              return _vm.$set(
                                                _vm.orderPagination,
                                                "pageSize",
                                                $event
                                              )
                                            },
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "dialog-footer",
                                        attrs: { slot: "footer" },
                                        slot: "footer",
                                      },
                                      [
                                        _c(
                                          "el-button",
                                          {
                                            staticStyle: {
                                              "min-width": "100px",
                                            },
                                            attrs: {
                                              round: "",
                                              plain: "",
                                              size: "small",
                                            },
                                            on: {
                                              click: function ($event) {
                                                _vm.dialogTableVisible = false
                                              },
                                            },
                                          },
                                          [_vm._v("Close")]
                                        ),
                                        _c(
                                          "el-button",
                                          {
                                            staticStyle: {
                                              "min-width": "100px",
                                            },
                                            attrs: {
                                              round: "",
                                              size: "small",
                                              type: "primary",
                                            },
                                            on: {
                                              click:
                                                _vm.ConfirmDialogTableVisible,
                                            },
                                          },
                                          [_vm._v("Confirm")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "span",
                                  { staticStyle: { "padding-left": "10px" } },
                                  [
                                    !_vm.isEdit && _vm.withOrder
                                      ? _c("el-button", {
                                          attrs: {
                                            type: "danger",
                                            icon: "el-icon-delete",
                                            circle: "",
                                            size: "mini",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.clearOrder(true)
                                            },
                                          },
                                        })
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm.withOrder
                  ? _c(
                      "el-row",
                      { staticClass: "product-items", attrs: { gutter: 10 } },
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 24 } },
                          [
                            _c("p", [_vm._v("Order Products")]),
                            _c(
                              "el-table",
                              {
                                staticStyle: { "background-color": "#f6f7fa" },
                                attrs: {
                                  data: _vm.selectedOrder.products,
                                  border: "",
                                  width: "100%",
                                  "header-cell-style": {
                                    fontSize: "16px",
                                    color: "#C0C4CC",
                                    height: "40px",
                                    backgroundColor: "#ebedf0",
                                  },
                                  "cell-style": {
                                    height: "40px",
                                    backgroundColor: "#F6F7FA",
                                  },
                                },
                              },
                              [
                                _c("el-table-column", {
                                  attrs: {
                                    prop: "sysProductName",
                                    label: "Product Name",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return [
                                            _c(
                                              "p",
                                              {
                                                staticStyle: {
                                                  margin: "0",
                                                  color: "#c0c4cc",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      scope.row.sysProductName
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    2264717070
                                  ),
                                }),
                                _c("el-table-column", {
                                  attrs: {
                                    prop: "sysProductSpecificationName",
                                    label: "Specs",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return [
                                            _c(
                                              "p",
                                              {
                                                staticStyle: {
                                                  margin: "0",
                                                  color: "#c0c4cc",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      scope.row
                                                        .sysProductSpecificationName
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    3561445843
                                  ),
                                }),
                                _c("el-table-column", {
                                  attrs: {
                                    prop: "minimumQuantity",
                                    label: "Min Qty",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return [
                                            _c(
                                              "p",
                                              {
                                                staticStyle: {
                                                  margin: "0",
                                                  color: "#c0c4cc",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      scope.row.minimumQuantity
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    4084765878
                                  ),
                                }),
                                _c("el-table-column", {
                                  attrs: {
                                    prop: "maximumQuantity",
                                    label: "Max Qty",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return [
                                            _c(
                                              "p",
                                              {
                                                staticStyle: {
                                                  margin: "0",
                                                  color: "#c0c4cc",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      scope.row.maximumQuantity
                                                        ? scope.row
                                                            .maximumQuantity
                                                        : "-"
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    51679411
                                  ),
                                }),
                                _c("el-table-column", {
                                  attrs: {
                                    prop: "finalQuantity",
                                    label: "Final Qty",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return [
                                            _c(
                                              "p",
                                              {
                                                staticStyle: {
                                                  margin: "0",
                                                  color: "#c0c4cc",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      scope.row.finalQuantity
                                                        ? scope.row
                                                            .finalQuantity
                                                        : "-"
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    1662419251
                                  ),
                                }),
                                _c("el-table-column", {
                                  attrs: {
                                    prop: "unitMetric",
                                    label: "Unit Metric",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return [
                                            _c(
                                              "p",
                                              {
                                                staticStyle: {
                                                  margin: "0",
                                                  color: "#c0c4cc",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(scope.row.unitMetric)
                                                ),
                                              ]
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    978599001
                                  ),
                                }),
                                _c("el-table-column", {
                                  attrs: {
                                    prop: "physicalSupplierBdn",
                                    label: "Physical Supplier BDN",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return [
                                            _c(
                                              "p",
                                              {
                                                staticStyle: {
                                                  margin: "0",
                                                  color: "#c0c4cc",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      scope.row
                                                        .physicalSupplierName
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    4218679965
                                  ),
                                }),
                              ],
                              1
                            ),
                            _vm.selectedOrder.sellerRemark
                              ? _c("p", [
                                  _vm._v(
                                    " *Remarks: " +
                                      _vm._s(_vm.selectedOrder.sellerRemark) +
                                      " "
                                  ),
                                ])
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.schedulFormData.mitigatingCompanyOrgId ===
                  _vm.currentCompany.id && _vm.isVictory
                  ? _c(
                      "el-row",
                      { attrs: { gutter: 10 } },
                      [
                        _c("el-col", [
                          _c(
                            "div",
                            { staticStyle: { "padding-bottom": "5px" } },
                            [
                              _c(
                                "span",
                                { staticStyle: { "padding-right": "10px" } },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.cashType,
                                        expression: "cashType",
                                      },
                                    ],
                                    attrs: {
                                      type: "radio",
                                      value: "non-cash",
                                      disabled: _vm.jobData.status >= 3,
                                    },
                                    domProps: {
                                      checked: _vm._q(_vm.cashType, "non-cash"),
                                    },
                                    on: {
                                      change: [
                                        function ($event) {
                                          _vm.cashType = "non-cash"
                                        },
                                        () => {
                                          _vm.addVictoryCompanyName()
                                          _vm.schedulFormData.victoryCashOrder =
                                            null
                                        },
                                      ],
                                    },
                                  }),
                                  _c("label", { attrs: { for: "non-cash" } }, [
                                    _vm._v("Non-Cash Order"),
                                  ]),
                                ]
                              ),
                              _c(
                                "span",
                                { staticStyle: { "padding-right": "10px" } },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.cashType,
                                        expression: "cashType",
                                      },
                                    ],
                                    attrs: {
                                      type: "radio",
                                      value: "cash",
                                      disabled: _vm.jobData.status >= 3,
                                    },
                                    domProps: {
                                      checked: _vm._q(_vm.cashType, "cash"),
                                    },
                                    on: {
                                      change: [
                                        function ($event) {
                                          _vm.cashType = "cash"
                                        },
                                        () => {
                                          _vm.addVictoryCompanyName()
                                        },
                                      ],
                                    },
                                  }),
                                  _c("label", { attrs: { for: "cash" } }, [
                                    _vm._v("Cash Order"),
                                  ]),
                                ]
                              ),
                            ]
                          ),
                        ]),
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "el-row",
                  { attrs: { gutter: 10 } },
                  [
                    _vm.cashType == "cash" && _vm.isVictory
                      ? [
                          _c(
                            "el-col",
                            { attrs: { sm: 12, md: 8 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "Pricing" } },
                                [
                                  _c("el-input", {
                                    attrs: { disabled: true },
                                    model: {
                                      value: _vm.tier4Price,
                                      callback: function ($$v) {
                                        _vm.tier4Price = $$v
                                      },
                                      expression: "tier4Price",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { sm: 12, md: 8 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "Cash Order Type" } },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        placeholder: "Select",
                                        disabled: _vm.jobData.status >= 3,
                                      },
                                      model: {
                                        value:
                                          _vm.schedulFormData.victoryCashOrder,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.schedulFormData,
                                            "victoryCashOrder",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "schedulFormData.victoryCashOrder",
                                      },
                                    },
                                    _vm._l(
                                      _vm.CASH_ORDER_TYPE_OPTIONS,
                                      function (item) {
                                        return _c("el-option", {
                                          key: item.value,
                                          attrs: {
                                            label: item.label,
                                            value: item.value,
                                          },
                                        })
                                      }
                                    ),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      : _c(
                          "el-col",
                          { attrs: { sm: 24, md: 8 } },
                          [
                            _vm.schedulFormData.mitigatingCompanyOrgId ===
                            _vm.currentCompany.id
                              ? _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      prop: "buyerSysOrganizationId",
                                      label: "Company Name",
                                      rules: _vm.isVictory
                                        ? _vm.rules.reqRule
                                        : null,
                                    },
                                  },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        staticStyle: { width: "100%" },
                                        attrs: {
                                          placeholder: "Select",
                                          filterable: "",
                                          clearable: true,
                                          disabled: _vm.jobData.status >= 3,
                                        },
                                        on: {
                                          "visible-change": function ($event) {
                                            return _vm.$store.dispatch(
                                              "getCreditAssociationList"
                                            )
                                          },
                                        },
                                        model: {
                                          value:
                                            _vm.schedulFormData
                                              .buyerSysOrganizationId,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.schedulFormData,
                                              "buyerSysOrganizationId",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "schedulFormData.buyerSysOrganizationId",
                                        },
                                      },
                                      _vm._l(
                                        _vm.buyerSysOrganizationList,
                                        function (item) {
                                          return _c("el-option", {
                                            key: item.id,
                                            attrs: {
                                              label:
                                                item.counterpartyCompanyName,
                                              value:
                                                item.counterpartySysOrganizationId,
                                              disabled:
                                                item.status !== "ACTIVE",
                                            },
                                          })
                                        }
                                      ),
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _c(
                                  "el-form-item",
                                  { attrs: { label: "Company Name" } },
                                  [
                                    _c("el-input", {
                                      attrs: { disabled: "" },
                                      model: {
                                        value: _vm.schedulFormData.buyer,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.schedulFormData,
                                            "buyer",
                                            $$v
                                          )
                                        },
                                        expression: "schedulFormData.buyer",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                          ],
                          1
                        ),
                    _c(
                      "el-col",
                      { attrs: { sm: 24, md: 4 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              prop: "stemStartDate",
                              label: "Stem Start Date",
                              rules: _vm.rules.reqRule,
                            },
                          },
                          [
                            _c("el-date-picker", {
                              staticStyle: { width: "100%" },
                              attrs: {
                                disabled:
                                  _vm.withOrder || _vm.jobData.status === 3,
                                type: "date",
                                placeholder: "Select",
                              },
                              model: {
                                value: _vm.schedulFormData.stemStartDate,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.schedulFormData,
                                    "stemStartDate",
                                    $$v
                                  )
                                },
                                expression: "schedulFormData.stemStartDate",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { sm: 24, md: 4 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              prop: "stemEndDate",
                              label: "Stem End Date",
                              rules: _vm.rules.reqRule,
                            },
                          },
                          [
                            _c("el-date-picker", {
                              staticStyle: { width: "100%" },
                              attrs: {
                                disabled:
                                  _vm.withOrder || _vm.jobData.status === 3,
                                type: "date",
                                placeholder: "Select",
                              },
                              model: {
                                value: _vm.schedulFormData.stemEndDate,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.schedulFormData,
                                    "stemEndDate",
                                    $$v
                                  )
                                },
                                expression: "schedulFormData.stemEndDate",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  2
                ),
                _c(
                  "el-row",
                  { attrs: { gutter: 10 } },
                  [
                    _c(
                      "el-col",
                      { attrs: { sm: 24, md: 8 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "Supply Type" } },
                          [
                            _c("el-input", {
                              attrs: { disabled: "" },
                              model: {
                                value: _vm.supplyType,
                                callback: function ($$v) {
                                  _vm.supplyType = $$v
                                },
                                expression: "supplyType",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { sm: 24, md: 8 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              prop: "nominationReference",
                              label: _vm.nominationReferenceLabel,
                            },
                          },
                          [
                            _c("el-input", {
                              model: {
                                value: _vm.schedulFormData.nominationReference,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.schedulFormData,
                                    "nominationReference",
                                    $$v
                                  )
                                },
                                expression:
                                  "schedulFormData.nominationReference",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { sm: 24, md: 8 } },
                      [
                        _vm.jobData.status == 4 &&
                        _vm.isJobCreatorOrBuyerEditView
                          ? _c(
                              "el-form",
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      prop: "referenceNo",
                                      label: "Reference No.",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      model: {
                                        value: _vm.schedulFormData.referenceNo,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.schedulFormData,
                                            "referenceNo",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "schedulFormData.referenceNo",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _c(
                              "el-form-item",
                              {
                                attrs: {
                                  prop: "referenceNo",
                                  label: "Reference No.",
                                },
                              },
                              [
                                _c("el-input", {
                                  model: {
                                    value: _vm.schedulFormData.referenceNo,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.schedulFormData,
                                        "referenceNo",
                                        $$v
                                      )
                                    },
                                    expression: "schedulFormData.referenceNo",
                                  },
                                }),
                              ],
                              1
                            ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-row",
                  { attrs: { gutter: 10 } },
                  [
                    _c(
                      "el-col",
                      { attrs: { sm: 24, md: 8 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              prop: "minQty",
                              label: "Min Quantity",
                              rules: _vm.rules.minQty,
                            },
                          },
                          [
                            _c(
                              "el-input",
                              {
                                staticClass: "input-with-select",
                                attrs: { type: "number" },
                                model: {
                                  value: _vm.schedulFormData.minQty,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.schedulFormData, "minQty", $$v)
                                  },
                                  expression: "schedulFormData.minQty",
                                },
                              },
                              [
                                _c("template", { slot: "append" }, [
                                  _vm._v(
                                    _vm._s(_vm.schedulFormData.qtyUnitMetric)
                                  ),
                                ]),
                              ],
                              2
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { sm: 24, md: 8 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              prop: "maxQty",
                              label: "Max Quantity",
                              rules: _vm.rules.maxQty,
                            },
                          },
                          [
                            _c(
                              "el-input",
                              {
                                staticClass: "input-with-select",
                                attrs: { type: "number" },
                                model: {
                                  value: _vm.schedulFormData.maxQty,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.schedulFormData, "maxQty", $$v)
                                  },
                                  expression: "schedulFormData.maxQty",
                                },
                              },
                              [
                                _c("template", { slot: "append" }, [
                                  _vm._v(
                                    _vm._s(_vm.schedulFormData.qtyUnitMetric)
                                  ),
                                ]),
                              ],
                              2
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { sm: 24, md: 8 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              prop: "finalQty",
                              label: "Final Quantity",
                              rules: _vm.rules.finalQty,
                            },
                          },
                          [
                            _c(
                              "el-input",
                              {
                                staticClass: "input-with-select",
                                attrs: { type: "number" },
                                model: {
                                  value: _vm.schedulFormData.finalQty,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.schedulFormData,
                                      "finalQty",
                                      $$v
                                    )
                                  },
                                  expression: "schedulFormData.finalQty",
                                },
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    attrs: { slot: "append", disabled: "" },
                                    slot: "append",
                                    model: {
                                      value: _vm.schedulFormData.qtyUnitMetric,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.schedulFormData,
                                          "qtyUnitMetric",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "schedulFormData.qtyUnitMetric",
                                    },
                                  },
                                  [
                                    _c("el-option", { attrs: { value: "MT" } }),
                                    _c("el-option", {
                                      attrs: { value: "CBM" },
                                    }),
                                    _c("el-option", {
                                      attrs: { value: "BARRELS" },
                                    }),
                                    _c("el-option", { attrs: { value: "%" } }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-row",
                  { attrs: { gutter: 10 } },
                  [
                    _c(
                      "el-col",
                      { attrs: { sm: 12, md: 8 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              prop: "sysProductId",
                              label: "Product Name",
                              rules: _vm.rules.reqRule,
                            },
                          },
                          [
                            _vm.withOrder && !_vm.isVictory
                              ? _c(
                                  "el-select",
                                  {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      placeholder: "Select",
                                      disabled: _vm.disabledForVictory,
                                    },
                                    on: { change: _vm.changeProduct },
                                    model: {
                                      value: _vm.selectedOrderProductSpec,
                                      callback: function ($$v) {
                                        _vm.selectedOrderProductSpec = $$v
                                      },
                                      expression: "selectedOrderProductSpec",
                                    },
                                  },
                                  _vm._l(
                                    _vm.selectedOrderProductSpecOptions,
                                    function (item) {
                                      return _c("el-option", {
                                        key: item.key,
                                        attrs: {
                                          label: item.sysProductName,
                                          value: item.key,
                                        },
                                      })
                                    }
                                  ),
                                  1
                                )
                              : _c(
                                  "el-select",
                                  {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      placeholder: "Select",
                                      loading:
                                        !_vm.sysProductOptions.length > 0,
                                      disabled: _vm.disabledForVictory,
                                    },
                                    on: {
                                      change: _vm.changeProduct,
                                      "visible-change":
                                        _vm.getProductListSortByRecent,
                                    },
                                    model: {
                                      value: _vm.schedulFormData.sysProductId,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.schedulFormData,
                                          "sysProductId",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "schedulFormData.sysProductId",
                                    },
                                  },
                                  _vm._l(
                                    _vm.sysProductOptions,
                                    function (item) {
                                      return _c("el-option", {
                                        key: item.id,
                                        attrs: {
                                          label: item.name,
                                          value: item.id,
                                        },
                                      })
                                    }
                                  ),
                                  1
                                ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { sm: 12, md: 8 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              prop: "sysProductSpecificationId",
                              label: "Spec",
                              rules: _vm.rules.reqRule,
                            },
                          },
                          [
                            _c(
                              "el-select",
                              {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  placeholder: "Select",
                                  disabled:
                                    _vm.withOrder || _vm.disabledForVictory,
                                },
                                on: { change: _vm.changeProductSpecification },
                                model: {
                                  value:
                                    _vm.schedulFormData
                                      .sysProductSpecificationId,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.schedulFormData,
                                      "sysProductSpecificationId",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "schedulFormData.sysProductSpecificationId",
                                },
                              },
                              _vm._l(
                                _vm.sysProductSpecificationOptions,
                                function (item) {
                                  return _c("el-option", {
                                    key: item.id,
                                    attrs: { label: item.name, value: item.id },
                                  })
                                }
                              ),
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c("fuel-type-related-information", {
                  attrs: {
                    schedulFormData: _vm.schedulFormData,
                    selectedProduct: _vm.selectedProduct,
                  },
                  on: {
                    "update:schedulFormData": function ($event) {
                      _vm.schedulFormData = $event
                    },
                    "update:schedul-form-data": function ($event) {
                      _vm.schedulFormData = $event
                    },
                    "update:selectedProduct": function ($event) {
                      _vm.selectedProduct = $event
                    },
                    "update:selected-product": function ($event) {
                      _vm.selectedProduct = $event
                    },
                  },
                }),
              ],
              1
            ),
          ]),
          _c(
            "base-detail-card",
            { attrs: { title: "BDN Information" } },
            [
              _c(
                "el-row",
                { attrs: { gutter: 10 } },
                [
                  _c(
                    "el-col",
                    { attrs: { sm: 24, md: 8 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            prop: "bargeOrgId",
                            label: "BDN",
                            rules: _vm.rules.reqRule,
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              attrs: {
                                filterable: "",
                                clearable: "",
                                disabled:
                                  _vm.withOrder ||
                                  (_vm.isEdit &&
                                    _vm.schedulFormData
                                      .mitigatingCompanyOrgId !==
                                      _vm.currentCompany.id),
                              },
                              on: {
                                change: (val) => _vm.handleChangeBDN(val, true),
                              },
                              model: {
                                value: _vm.schedulFormData.bargeOrgId,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.schedulFormData,
                                    "bargeOrgId",
                                    $$v
                                  )
                                },
                                expression: "schedulFormData.bargeOrgId",
                              },
                            },
                            _vm._l(
                              _vm.physicalSupplierOptions,
                              function (item) {
                                return _c("el-option", {
                                  key: item.id,
                                  attrs: {
                                    label: item.companyName,
                                    value: item.id,
                                  },
                                })
                              }
                            ),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { sm: 24, md: 8 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            prop: "numberOfBdn",
                            label: "No. Of BDN",
                            rules: _vm.rules.reqRule,
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              on: { change: _vm.handleChangeNumberOfBdn },
                              model: {
                                value: _vm.schedulFormData.numberOfBdn,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.schedulFormData,
                                    "numberOfBdn",
                                    $$v
                                  )
                                },
                                expression: "schedulFormData.numberOfBdn",
                              },
                            },
                            [
                              _c("el-option", { attrs: { value: "1" } }, [
                                _vm._v("1"),
                              ]),
                              _c("el-option", { attrs: { value: "2" } }, [
                                _vm._v("2"),
                              ]),
                              _c("el-option", { attrs: { value: "3" } }, [
                                _vm._v("3"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.isExxonMobil
                    ? _c(
                        "el-col",
                        { attrs: { sm: 24, md: 8 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                prop: "customerName",
                                label: "Customer Name",
                                rules: _vm.rules.reqRule,
                              },
                            },
                            [
                              _c("el-input", {
                                model: {
                                  value: _vm.schedulFormData.customerName,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.schedulFormData,
                                      "customerName",
                                      $$v
                                    )
                                  },
                                  expression: "schedulFormData.customerName",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "el-col",
                    { attrs: { sm: 24, md: 8 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "Operation Date Base On",
                            rules: _vm.rules.reqRule,
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              attrs: {
                                placeholder: "Please Select",
                                disabled:
                                  _vm.isDisabledAllowOperationDateChange,
                              },
                              model: {
                                value:
                                  _vm.schedulFormData.selectedOperationDateType,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.schedulFormData,
                                    "selectedOperationDateType",
                                    $$v
                                  )
                                },
                                expression:
                                  "schedulFormData.selectedOperationDateType",
                              },
                            },
                            _vm._l(
                              _vm.DELIVERY_OPERATION_DATE_OPTIONS,
                              function (item) {
                                return _c("el-option", {
                                  attrs: {
                                    label: item.label,
                                    value: item.value,
                                  },
                                })
                              }
                            ),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.schedulFormData.bdnQuantities?.length > 1
                ? _c(
                    "el-row",
                    { attrs: { gutter: 10 } },
                    _vm._l(
                      _vm.schedulFormData.bdnQuantities,
                      function (item, index) {
                        return _c(
                          "el-col",
                          { key: index, attrs: { sm: 24, md: 8 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  prop: `bdnQuantities.${index}`,
                                  label: `Bdn Quantity ${index + 1}`,
                                  rules: _vm.rules.bdnQuantity,
                                },
                              },
                              [
                                _c("el-input", {
                                  attrs: { type: "number", min: 0 },
                                  model: {
                                    value:
                                      _vm.schedulFormData.bdnQuantities[index],
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.schedulFormData.bdnQuantities,
                                        index,
                                        $$v
                                      )
                                    },
                                    expression:
                                      "schedulFormData.bdnQuantities[index]",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      }
                    ),
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "base-detail-card",
            { attrs: { title: "Barge Information" } },
            [
              _c(
                "el-row",
                { attrs: { gutter: 10 } },
                [
                  _c(
                    "el-col",
                    { attrs: { sm: 24, md: 8 } },
                    [
                      _vm.schedulFormData.mitigatingCompanyOrgId ===
                        _vm.currentCompany.id || !_vm.isEdit
                        ? _c(
                            "el-form-item",
                            {
                              attrs: { prop: "bargeName", label: "Barge Name" },
                            },
                            [
                              _c("barge-or-vessel-selector", {
                                attrs: {
                                  disabled:
                                    [2, 3, 4, 5, 6].includes(
                                      _vm.jobData.status
                                    ) || !_vm.isJobCreatorOrBuyerEditView,
                                  schedulFormData: _vm.schedulFormData,
                                  availableBargesLoading:
                                    _vm.availableBargesLoading,
                                  availableBargesOptions:
                                    _vm.availableBargesOptions,
                                },
                                on: {
                                  handleChangeBarge: _vm.handleChangeBarge,
                                  visibleChange: _vm.visibleChange,
                                },
                              }),
                              _vm.supplyingBargeMpaStatus
                                ? _c(
                                    "span",
                                    { staticStyle: { "font-size": "10px" } },
                                    [
                                      _vm._v(
                                        "(" +
                                          _vm._s(_vm.supplyingBargeMpaStatus) +
                                          ")"
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ],
                            1
                          )
                        : _c(
                            "el-form-item",
                            { attrs: { label: "Barge Name" } },
                            [
                              _c("el-input", {
                                attrs: { disabled: "" },
                                model: {
                                  value: _vm.schedulFormData.bargeName,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.schedulFormData,
                                      "bargeName",
                                      $$v
                                    )
                                  },
                                  expression: "schedulFormData.bargeName",
                                },
                              }),
                              _vm.supplyingBargeMpaStatus
                                ? _c(
                                    "span",
                                    { staticStyle: { "font-size": "10px" } },
                                    [
                                      _vm._v(
                                        "(" +
                                          _vm._s(_vm.supplyingBargeMpaStatus) +
                                          ")"
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { sm: 12, md: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "IMO Number" } },
                        [
                          _c("el-input", {
                            attrs: { disabled: "" },
                            model: {
                              value: _vm.schedulFormData.bargeImo,
                              callback: function ($$v) {
                                _vm.$set(_vm.schedulFormData, "bargeImo", $$v)
                              },
                              expression: "schedulFormData.bargeImo",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { sm: 12, md: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "SB No." } },
                        [
                          _c("el-input", {
                            attrs: { disabled: "" },
                            model: {
                              value: _vm.barge.licenceNo,
                              callback: function ($$v) {
                                _vm.$set(_vm.barge, "licenceNo", $$v)
                              },
                              expression: "barge.licenceNo",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "base-detail-card",
            { attrs: { title: "Vessel Information" } },
            [
              _c(
                "el-row",
                {
                  staticClass: "search-vessel-suffix-hidden",
                  attrs: { gutter: 10 },
                },
                [
                  _c(
                    "el-col",
                    { attrs: { sm: 24, md: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "Search Vessel" } },
                        [
                          _c("search-vessel-selector", {
                            attrs: {
                              disabled:
                                _vm.withOrder ||
                                [3, 4, 5, 6].includes(_vm.jobData.status) ||
                                !_vm.isJobCreatorOrBuyerEditView,
                              searchedVesselList: _vm.searchedVesselList,
                              cashType: _vm.cashType,
                              schedulFormData: _vm.schedulFormData,
                            },
                            on: {
                              "update:searchedVesselList": function ($event) {
                                _vm.searchedVesselList = $event
                              },
                              "update:searched-vessel-list": function ($event) {
                                _vm.searchedVesselList = $event
                              },
                              handleSelectVessel: _vm.handleSelectVessel,
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.showDBvesselName
                    ? _c(
                        "el-col",
                        { attrs: { sm: 24, md: 16 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                prop: "vesselNameDatabase",
                                label: "Vessel Name in Database",
                              },
                            },
                            [
                              _c("template", { slot: "label" }, [
                                _c("div", [
                                  _vm._v("Vessel Name in Database "),
                                  _c(
                                    "span",
                                    { staticStyle: { color: "red" } },
                                    [_vm._v("(Difference from Vessel Name)")]
                                  ),
                                ]),
                              ]),
                              _c("el-input", {
                                attrs: { disabled: true },
                                model: {
                                  value: _vm.schedulFormData.vesselNameDatabase,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.schedulFormData,
                                      "vesselNameDatabase",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "schedulFormData.vesselNameDatabase",
                                },
                              }),
                            ],
                            2
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "el-row",
                { attrs: { gutter: 10 } },
                [
                  _c(
                    "el-col",
                    { attrs: { sm: 24, md: 8 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            prop: "vesselName",
                            label: "Vessel Name",
                            rules: _vm.rules.vesselName,
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              disabled:
                                _vm.withOrder ||
                                [3, 4, 5, 6].includes(_vm.jobData.status) ||
                                !_vm.isJobCreatorOrBuyerEditView,
                            },
                            model: {
                              value: _vm.schedulFormData.vesselName,
                              callback: function ($$v) {
                                _vm.$set(_vm.schedulFormData, "vesselName", $$v)
                              },
                              expression: "schedulFormData.vesselName",
                            },
                          }),
                          _vm.receivingVesselMpaStatus &&
                          _vm.schedulFormData.vesselName != null &&
                          _vm.schedulFormData.vesselName.length > 0
                            ? _c(
                                "span",
                                { staticStyle: { "font-size": "10px" } },
                                [
                                  _vm._v(
                                    "(" +
                                      _vm._s(_vm.receivingVesselMpaStatus) +
                                      ")"
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { sm: 12, md: 8 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "IMO Number",
                            prop: "vesselImo",
                            rules: _vm.rules.vesselImoNo,
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              disabled:
                                _vm.withOrder ||
                                [3, 4, 5, 6].includes(_vm.jobData.status) ||
                                !_vm.isJobCreatorOrBuyerEditView,
                            },
                            on: {
                              change: (val) =>
                                _vm.getVessel(
                                  val,
                                  "imo",
                                  _vm.getVesselCallback
                                ),
                            },
                            model: {
                              value: _vm.schedulFormData.vesselImo,
                              callback: function ($$v) {
                                _vm.$set(_vm.schedulFormData, "vesselImo", $$v)
                              },
                              expression: "schedulFormData.vesselImo",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { sm: 12, md: 8 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            prop: "vesselLicenseNo",
                            label: "License Number",
                            rules: [
                              ..._vm.rules.validateLicenseNo,
                              ..._vm.LICENSE_NUMBKER_RULE,
                            ],
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              disabled:
                                _vm.withOrder ||
                                [3, 4, 5, 6].includes(_vm.jobData.status) ||
                                !_vm.isJobCreatorOrBuyerEditView,
                            },
                            on: {
                              change: (val) =>
                                _vm.getVessel(
                                  val,
                                  "licenceNo",
                                  _vm.getVesselCallback
                                ),
                            },
                            model: {
                              value: _vm.schedulFormData.vesselLicenseNo,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.schedulFormData,
                                  "vesselLicenseNo",
                                  $$v
                                )
                              },
                              expression: "schedulFormData.vesselLicenseNo",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { sm: 24, md: 8 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            prop: "vesselEmail",
                            label: "Email",
                            rules: _vm.GENERAL_EMAIL_RULE,
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              disabled:
                                _vm.withOrder ||
                                [3, 4, 5, 6].includes(_vm.jobData.status) ||
                                !_vm.isJobCreatorOrBuyerEditView,
                            },
                            model: {
                              value: _vm.schedulFormData.vesselEmail,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.schedulFormData,
                                  "vesselEmail",
                                  $$v
                                )
                              },
                              expression: "schedulFormData.vesselEmail",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "base-detail-card",
            { attrs: { title: "Location Information" } },
            [
              _c(
                "el-row",
                { attrs: { gutter: 10 } },
                [
                  _c(
                    "el-col",
                    { attrs: { sm: 12, md: 8 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            prop: "port",
                            label: "Port",
                            rules: _vm.rules.reqRule,
                          },
                        },
                        [
                          _c("el-input", {
                            model: {
                              value: _vm.schedulFormData.port,
                              callback: function ($$v) {
                                _vm.$set(_vm.schedulFormData, "port", $$v)
                              },
                              expression: "schedulFormData.port",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { sm: 12, md: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { prop: "vesselEta", label: "Date & Time" } },
                        [
                          _c("el-date-picker", {
                            staticStyle: { width: "100%" },
                            attrs: {
                              type: "datetime",
                              format: "yyyy-MM-dd HH:mm",
                              placeholder: "Select",
                            },
                            model: {
                              value: _vm.schedulFormData.vesselEta,
                              callback: function ($$v) {
                                _vm.$set(_vm.schedulFormData, "vesselEta", $$v)
                              },
                              expression: "schedulFormData.vesselEta",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { sm: 12, md: 8 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            prop: "locationLevel",
                            label: "Anchorage/Berth",
                          },
                        },
                        [
                          _c("el-cascader", {
                            staticStyle: { width: "100%" },
                            attrs: {
                              clearable: "",
                              "popper-class": "el-cascader-long",
                              options: _vm.locationList,
                              props: _vm.locationOptionProps,
                            },
                            model: {
                              value: _vm.schedulFormData.locationLevel,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.schedulFormData,
                                  "locationLevel",
                                  $$v
                                )
                              },
                              expression: "schedulFormData.locationLevel",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "base-detail-card",
            { attrs: { title: "Third Party Information" } },
            [
              _c(
                "el-row",
                { attrs: { gutter: 10 } },
                [
                  _c(
                    "el-col",
                    { attrs: { sm: 12, md: 8 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            prop: "agentCompany",
                            label: "Agent Company",
                          },
                        },
                        [
                          _c("el-input", {
                            on: { input: _vm.change },
                            model: {
                              value: _vm.schedulFormData.agentCompany,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.schedulFormData,
                                  "agentCompany",
                                  $$v
                                )
                              },
                              expression: "schedulFormData.agentCompany",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { sm: 12, md: 8 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            prop: "agentContact",
                            label: "Agent Contact",
                          },
                        },
                        [
                          _c("el-input", {
                            on: { input: _vm.change },
                            model: {
                              value: _vm.schedulFormData.agentContact,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.schedulFormData,
                                  "agentContact",
                                  $$v
                                )
                              },
                              expression: "schedulFormData.agentContact",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                { attrs: { gutter: 10 } },
                [
                  _c(
                    "el-col",
                    { attrs: { sm: 12, md: 8 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            prop: "surveyorCompany",
                            label: "Surveyor Company",
                          },
                        },
                        [
                          _c("el-input", {
                            on: { input: _vm.change },
                            model: {
                              value: _vm.schedulFormData.surveyorCompany,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.schedulFormData,
                                  "surveyorCompany",
                                  $$v
                                )
                              },
                              expression: "schedulFormData.surveyorCompany",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { sm: 12, md: 8 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            prop: "surveyorContact",
                            label: "Surveyor Contact",
                          },
                        },
                        [
                          _c("el-input", {
                            on: { input: _vm.change },
                            model: {
                              value: _vm.schedulFormData.surveyorContact,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.schedulFormData,
                                  "surveyorContact",
                                  $$v
                                )
                              },
                              expression: "schedulFormData.surveyorContact",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { sm: 12, md: 8 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            prop: "surveyorEmail",
                            label: "Surveyor Email",
                            rules: _vm.GENERAL_EMAIL_RULE,
                          },
                        },
                        [
                          _c("el-input", {
                            on: { input: _vm.change },
                            model: {
                              value: _vm.schedulFormData.surveyorEmail,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.schedulFormData,
                                  "surveyorEmail",
                                  $$v
                                )
                              },
                              expression: "schedulFormData.surveyorEmail",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "base-detail-card",
            { attrs: { title: "Remarks" } },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-form-item",
                    { attrs: { prop: "remark" } },
                    [
                      _c("el-input", {
                        attrs: { type: "textarea", rows: 2 },
                        model: {
                          value: _vm.schedulFormData.remark,
                          callback: function ($$v) {
                            _vm.$set(_vm.schedulFormData, "remark", $$v)
                          },
                          expression: "schedulFormData.remark",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form",
            [
              _vm.jobData.id
                ? _c(
                    "base-detail-card",
                    { attrs: { title: "Audit Trails" } },
                    [
                      _c("audit-trails-information", {
                        attrs: { jobId: _vm.jobData.id },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.jobData.status == 4
                ? _c(
                    "base-detail-card",
                    {
                      attrs: {
                        title: "MPA Transaction Information",
                        actions: _vm.mpaInfoActions,
                      },
                    },
                    [
                      _c("mpa-transaction-information", {
                        attrs: { mpaTransactionData: _vm.mpaTransactionData },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.jobData.status == 4 && _vm.isVictory
                ? _c(
                    "base-detail-card",
                    {
                      attrs: {
                        title: "SAGE Information",
                        actions: _vm.sageInfoActions,
                      },
                    },
                    [
                      _c("SageInformation", {
                        key: _vm.sageInfoKey,
                        attrs: { jobId: _vm.jobData.id },
                        on: { hasSuccess: _vm.handleSageInfoHasSuccess },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.sgTradexState &&
              _vm.jobData.status === 4 &&
              _vm.sgTradexState.isSgTradexInfoVisible
                ? _c(
                    "base-detail-card",
                    {
                      attrs: {
                        title: "SgTradex Transaction Information",
                        action: _vm.sgTradexState.actions,
                      },
                    },
                    [
                      _c("sg-tradex-transaction-information", {
                        attrs: {
                          sgTradexTransactionData:
                            _vm.sgTradexState.sgTradexTransactionData,
                          loading: _vm.sgTradexState.sgTradexTransactionLoading,
                        },
                        on: {
                          "update:sgTradexTransactionData": function ($event) {
                            return _vm.$set(
                              _vm.sgTradexState,
                              "sgTradexTransactionData",
                              $event
                            )
                          },
                          "update:sg-tradex-transaction-data": function (
                            $event
                          ) {
                            return _vm.$set(
                              _vm.sgTradexState,
                              "sgTradexTransactionData",
                              $event
                            )
                          },
                          "update:loading": function ($event) {
                            return _vm.$set(
                              _vm.sgTradexState,
                              "sgTradexTransactionLoading",
                              $event
                            )
                          },
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _vm.jobData.id
        ? _c("email-recipients", {
            attrs: {
              jobId: _vm.jobData.id,
              jobStatus: _vm.jobData.status,
              showDialog: _vm.showEmailRecipientDialog,
            },
            on: {
              "on-dialog-closed": () => (_vm.showEmailRecipientDialog = false),
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }